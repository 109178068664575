import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import InfoModal from "../InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { setChecked } from "../../redux/reducers/emissionSwitchChecked";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EmissionTab = ({ activeTab, showBtn = true }) => {
  const dispatch = useDispatch();

  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [chartKey, setChartKey] = useState(0);
  const [show, setShow] = useState(false);

  const checked = useSelector((state) => state.emissionSwitchChecked);
  const { fromYear } = useSelector((state) => state.selectedYearItem);

  const handleChange = (event) => {
    dispatch(setChecked(event.target.checked));
  };

  const emission = useSelector((state) => state.pdfData);

  useEffect(() => {
    if (Object.keys(emission).length > 0) {
      const myResultsEmissions =
        emission?.myResultsEmissions?.myResultsEmissions;
      const labels = Object.keys(myResultsEmissions);
      const pollutantNames = Array.from(
        new Set(
          Object.values(myResultsEmissions).flatMap((location) =>
            Object.values(location).flatMap(
              (pollutant) => pollutant.pollutantName
            )
          )
        )
      );

      const datasets = pollutantNames.map((pollutant) => {
        const co2eq_EmittedMt = labels.map((label) => {
          const etsData = myResultsEmissions[label];
          return etsData[pollutant]
            ? etsData[pollutant].co2eq_EmittedMt.toFixed(2)
            : 0;
        });

        const quantityEmittedMt = labels.map((label) => {
          const etsData = myResultsEmissions[label];
          return etsData[pollutant]
            ? etsData[pollutant].quantityEmittedMt.toFixed(2)
            : 0;
        });

        const backgroundColors = labels.map((label) => {
          const etsData = myResultsEmissions[label];
          return etsData[pollutant]
            ? etsData[pollutant].renderStyle.myColor
            : "";
        });
        const filteredColors = backgroundColors.filter((color) => color !== "");
        const backgroundColor = Array.from(new Set(filteredColors));

        if (checked) {
          return {
            label: pollutant,
            data: co2eq_EmittedMt,
            quantityEmittedMt: quantityEmittedMt,
            co2eq_EmittedMt: co2eq_EmittedMt,
            backgroundColor,
            barThickness: 10,
            border: 0,
            barPercentage: 0.6,
            categoryPercentage: 0.4,
          };
        } else {
          return {
            label: pollutant,
            data: quantityEmittedMt,
            quantityEmittedMt: quantityEmittedMt,
            co2eq_EmittedMt: co2eq_EmittedMt,
            backgroundColor,
            barThickness: 10,
            border: 0,
            barPercentage: 0.6,
            categoryPercentage: 0.4,
          };
        }
      });
      setDataset(datasets);
      setLabel(labels);
    }
  }, [emission, checked]);

  useEffect(() => {
    if (activeTab === 4) {
      setChartKey((prevKey) => prevKey + 1);
    }
  }, [activeTab]);

  const dataEts = {
    labels: label,
    datasets: dataset,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        grid: {
          display: true,
        },
        display: true,
        title: {
          display: true,
          text: `Emissions in Mt`,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"
          },
          padding: {
            top: 30,
          },
        },
        ticks: {
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          crossAlign: "far",
          color: "#000000",
          padding: 20,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"

          },
          autoSkip:false,
        },
        border: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 75
      }
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositionerLeft",
        displayColors: false,
        callbacks: {
          label: function (context) {
            if (context !== undefined) {
              const dataIndex = context.dataIndex;
              const Mt = context.dataset.quantityEmittedMt[dataIndex];
              const co2 = context.dataset.co2eq_EmittedMt[dataIndex];
              return [
                `Quantity Emitted: ${formatNumber(Mt)} mt`,
                `CO2 eq: ${formatNumber(co2)} mt `,
              ];
            }
            return context.label;
          },
          title: function (context) {
            if (context[0] !== undefined) {
              return context[0].label + "-" + context[0].dataset.label;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 40,
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
        },
      },
    },
  };

  const emissionContainerRef = useRef(null);
  const emissionChartRef = useRef(null);

  useEffect(() => {
    if (show) {
      emissionContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    emissionChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div ref={emissionContainerRef}>
      <div className="tab-content-title fuel">
        <h2>
          Emissions <span className="fs-4"> {fromYear.item}</span>
        </h2>
        {showBtn && (
          <div className="d-flex align-items-center gap-3">
            <div className="form-check form-switch ft-1rem">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                id="custom-switch"
                label={checked ? "Mt CO2-eq" : "Mt Pollutant"}
                checked={checked}
                onChange={handleChange}

              /><span className="form-check-label">{checked ? "Mt CO2-eq" : "Mt Pollutant"}</span>
            </div>
            <div>
              <button className="btn btn-primary" onClick={() => setShow(!show)}>
                Notes and Sources
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="fleet-chart-container">
        {Object.keys(emission).length === 0 &&
          label.length === 0 &&
          dataset.length === 0 && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Loading loading={Object.keys(emission).length === 0} />
            </div>
          )}
        {Object.keys(emission).length > 0 &&
          label.length > 0 &&
          dataset.length > 0 && (
            <>
              <Bar data={dataEts} options={options} key={chartKey} />
            </>
          )}
      </div>
      <InfoModal
        ref={emissionChartRef}
        show={show}
        handleClose={handleClose}
        data={emission?.myResultsEmissions?.comments}
      />
    </div>
  );
};

export default EmissionTab;
