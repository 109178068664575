import {  useContext } from "react";

import { setPermissions } from "../redux/reducers/permissionsSlice";
import { setFreeRevenueValue } from "../redux/reducers/revenueValueSlice";
import { setLegislativeScenarioData } from "../redux/reducers/selectedLegislativeScenario";
import { setPricescenarioData } from "../redux/reducers/selectedPriceScenario";
import { clearRefineData } from "../redux/reducers/refineDataSlice";
import { setSelectedBasins } from "../redux/reducers/selectedBasin";
import { setPdfData } from "../redux/reducers/pdfData";
import { setChecked } from "../redux/reducers/emissionSwitchChecked";
import { setSelctedPurchaseBy } from "../redux/reducers/fuelPurchaseBy";
import { setSelectedVesselItems } from "../redux/reducers/selectedVesselItemsReducer";
import {
    setFromYear,
    setIsPartialYear,
    setLegislativeScenario,
    setPriceScenario,
    setSelectAll,
    setToYear,
  } from "../redux/reducers/selectedYearSlice";
  
import AuthContext from "../contexts/AuthContext";
import { persistor } from "../redux/store";
import { useDispatch } from "react-redux";
import { setBasePrice, setFindFuelType, setFuelFamily, setFuelOrigin, setInflationRate, setPriceUnit } from "../redux/reducers/selectedtemplateData";

export default function useLogout() {
    const dispatch = useDispatch()
    const { setIsLoggedIn } = useContext(AuthContext);
    const handleLogOut = async () => {
        await persistor.purge();
        dispatch(setSelectedVesselItems({}));
        dispatch(setFromYear({ id: null, item: "Select Year" }));
        dispatch(setToYear({ id: null, item: "Select Year" }));
        dispatch(setLegislativeScenario({ id: null, item: "Select" }));
        dispatch(setPriceScenario({ id: null, item: "Select" }));
        dispatch(setLegislativeScenarioData({ id: null, item: "Select" }));
        dispatch(setPermissions([]));
        dispatch(setFreeRevenueValue({}));
        dispatch(setSelectedBasins({}));
        dispatch(setPricescenarioData({ id: null, item: "Select" }));
        dispatch(clearRefineData());
        dispatch(setPdfData({}));
        dispatch(setChecked(true));
        dispatch(setSelctedPurchaseBy({ id: 1, unit: "Expense [USD]" }));
        dispatch(setFuelFamily({ id: null, item: 'Select' }));
        dispatch(setBasePrice({ id: null, item: 'Select' }));
        dispatch(setInflationRate({ id: null, item: 'Select' }));
        dispatch(setFuelOrigin({ id: null, item: 'Select' }));
        dispatch(setPriceUnit({ id: null, item: '/mt' }));
        dispatch(setFindFuelType({ id: null, item: 'Select' }));
        dispatch(setIsPartialYear(false));
        dispatch(setSelectAll(false));
        localStorage.clear();
        setIsLoggedIn(false);
    };
    return handleLogOut;
}