import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "./Navbar";

const AuthOutlet = () => {
  return (
    <>
      <div className="login-container">
        <Navbar />
        <div className="py-5">
          <div className="row">
            <div className="col-lg-7">
              <div className="login_left_container">
                <div className="login-image">
                  <img src={"https://storage.googleapis.com/vessel-images/pool-login-splash.png"} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="login_right_container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthOutlet;
