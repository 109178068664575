import React, { useEffect, useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import "../css/main.css";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useLocation } from "react-router-dom";

import PassIcon from "../assets/passIcon.svg";
import axios from "axios";
import { reactAppConfig } from "../config/react-app-config";


const schemaPass = yup
  .object({
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters"),
    confirmpassword: yup
      .string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

const ResetPassword = () => {
  const {
    register: registerPass,
    handleSubmit: handlePass,
    clearErrors,
    reset,
    formState: { errors: errorPass },
  } = useForm({
    resolver: yupResolver(schemaPass),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [token, setToken] = useState("");

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<MdOutlineVisibilityOff />);
  const [showLoading, setShowLoading] = useState(false);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(<MdOutlineVisibility />);
      setType("text");
    } else {
      setIcon(<MdOutlineVisibilityOff />);
      setType("password");
    }
  };

  useEffect(() => {
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    if (params.token) {
      setToken(params.token);
    }
  }, []);

  const handleLogin = (data) => {
    setShowLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${reactAppConfig.baseUrl}/api/v2/userManagement/reset-password?token=${token}&password=${data.password}`,
      headers: {
        accept: "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        toast.success(" Password Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          toastId: "password-updated",
        });

        navigate("/");
        reset({ password: "", confirmpassword: "" });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "pass-update-err",
        });
        reset({ password: "", confirmpassword: "" });
      })
      .finally(() => setShowLoading(false));
  };
  return (
    <>
      <div className="login-detail">
        <div className="text-center pb-4 forgot-password-container">
          <h2>Reset Your Password</h2>
        </div>
        <form onSubmit={handlePass(handleLogin)}>
          <div className="pass-input pos-relative mt-3">
            <img src={PassIcon} alt="" />
            <div className="visibility-icon" onClick={handleToggle}>
              {icon}
            </div>
            <input
              className={`${errorPass.password ? "error-input" : ""}`}
              type={type}
              name="password"
              placeholder="Enter Your Password"
              {...registerPass("password")}
              onBlur={(e) => {
                const { name } = e.target;
                if (name === "password" && errorPass.password) {
                  clearErrors("password");
                }
              }}
            />
          </div>
          <div className="pass-input pos-relative mt-3">
            <img src={PassIcon} alt="" />
            <div className="visibility-icon" onClick={handleToggle}>
              {icon}
            </div>
            <input
              className={`${errorPass.confirmpassword ? "error-input" : ""}`}
              type={type}
              name="confirmpassword"
              placeholder="Enter Your Password"
              {...registerPass("confirmpassword")}
              onBlur={(e) => {
                const { name } = e.target;
                if (name === "confirmpassword" && errorPass.confirmpassword) {
                  clearErrors("confirmpassword");
                }
              }}
            />
          </div>
          <div className="text-center mt-4">
            <button className="btn btn-primary btn-lg" type="submit">
              Reset
              {showLoading && (
                <div className="spinner-border" role="status"></div>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
