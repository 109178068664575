import React, { useEffect, useRef, useState } from "react";

import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import DownArrowIcon from "../../assets/arrowDownIcon.svg";
import UpArrowIcon from "../../assets/arrowUpIcon.svg";
import InfoModal from "../InfoModal";
import { useSelector } from "react-redux";
import CustomTooltip from "../CustomTooltip";

const RecommendationTab = ({ activeTab, showBtn = true }) => {
  const { fromYear } = useSelector((state) => state.selectedYearItem);
  const data = useSelector((state) => state.pdfData);

  const ciiData = data?.myCiiResults;
  const eufmData = data?.myFMResults;
  const speedData = data?.mySpeedResults;
  const fuelMix = data?.myResultsFuelMix;
  const caseId = data?.caseId;

  const [vesselName, setVesselName] = useState([]);
  const [ciiValue, setCiiValue] = useState([]);
  const [bg, setBg] = useState([]);

  const [eufmDataset, setEufmDataset] = useState([]);
  const [speedDataset, setSpeedDataset] = useState([]);
  const [fuelMixDataset, setFuelMixDataset] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const [show, setShow] = useState(false);

  const newData = vesselName.map((item, index) => ({
    vessel: item,
    CII: ciiValue[index],
    bg: bg[index],
    id: index + 1,
    speed: speedDataset[index],
    fuel: fuelMixDataset[index],
    eufm: eufmDataset[index],
  }));
  useEffect(() => {
    if (data && ciiData && eufmData && speedData && fuelMix) {
      const resultCii = ciiData?.myResultsCII;

      let vesselName = Object.keys(resultCii);

      const resultEuFm = eufmData?.myResultsFM;

      const resultSpeed = speedData?.myResultsSpeed;

      let euFmDatasets = [];
      let speedDatasets = [];
      let fuelMixDatasets = [];

      vesselName.map((name) => {
        const eufm = [resultEuFm[name]];

        const data = eufm.map((e, i) => {
          return {
            label: e.vesselName,
            attainedFMintensity: +e.attainedFMintensity.toFixed(2),
            attainedFMintensity_UnaidedByF:
              +e.attainedFMintensity_UnaidedByF.toFixed(2),
            fmcredit_mtCO2eq:
              e.fmcredit_mtCO2eq === 0 ? +e.fmcredit_mtCO2eq : +e.fmcredit_mtCO2eq.toFixed(2),
            miniKPIlength:
              e.miniKPIlength === 0
                ? +e.miniKPIlength
                : +e.miniKPIlength.toFixed(2),
          };
        });

        euFmDatasets.push(data);
      });

      setEufmDataset(euFmDatasets);

      vesselName.map((name) => {
        const speed = [resultSpeed[name]];
        const data = speed.map((e, index) => {
          return {
            label: e.vesselName,
            celerity: e.celerity,
            celerityColor: e.celerityColor,
            maxSpeed: e.maxSpeed,
            minSpeed: e.minSpeed,
            optimalSpeed: e.optimalSpeed,
            targetSpeed: e.targetSpeed,
          };
        });
        speedDatasets.push(data);
      });

      setSpeedDataset(speedDatasets);

      let cii = Object.values(resultCii).map((item) => item.ciidesignation);
      let bg = Object.values(resultCii).map((item) => item.colorHex);
      setVesselName(vesselName);
      setCiiValue(cii);
      setBg(bg);

      const resultFuelMix = fuelMix?.myResultsFuelMix;

      vesselName.map((name) => {
        const fuelMix = resultFuelMix[name];
        const tempData = Object.values(fuelMix).map((item) => item);
        const uniqueName = [...new Set(tempData.map((p) => p.originString))];

        const data = tempData.map((p, index) => {
          return {
            label: uniqueName[index],
            data: +p.ptg.toFixed(2),
            backgroundColor: p.colorHex,
            originString: p.originString,
            vesselname: name,
          };
        });

        fuelMixDatasets.push(data);
      });

      setFuelMixDataset(fuelMixDatasets);
    }
  }, [data]);

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...newData].sort((a, b) => {
    if (sortConfig.key === "vessel") {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] < b[sortConfig.key] ? -1 : 1;
      } else {
        return a[sortConfig.key] > b[sortConfig.key] ? -1 : 1;
      }
    } else if (sortConfig.key === "CII") {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] < b[sortConfig.key] ? -1 : 1;
      } else {
        return a[sortConfig.key] > b[sortConfig.key] ? -1 : 1;
      }
    } else if (sortConfig.key === "speed") {
      const optimalSpeedA = a.speed[0]?.optimalSpeed || 0;
      const optimalSpeedB = b.speed[0]?.optimalSpeed || 0;
      if (sortConfig.direction === "asc") {
        return optimalSpeedA - optimalSpeedB;
      } else {
        return optimalSpeedB - optimalSpeedA;
      }
    } else if (sortConfig.key === "fuel") {
      const fossilA =
        a.fuel.find((item) => item.originString === "fossil")?.data || 0;
      const fossilB =
        b.fuel.find((item) => item.originString === "fossil")?.data || 0;

      if (sortConfig.direction === "asc") {
        return fossilA - fossilB;
      } else {
        return fossilB - fossilA;
      }
    } else if (sortConfig.key === "eufm") {
      const attainedFMintensityA = a.eufm[0]?.fmcredit_mtCO2eq || 0;
      const attainedFMintensityB = b.eufm[0]?.fmcredit_mtCO2eq || 0;
      if (sortConfig.direction === "asc") {
        return attainedFMintensityA - attainedFMintensityB;
      } else {
        return attainedFMintensityB - attainedFMintensityA;
      }
    } else {
      return 0;
    }
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (activeTab === 6) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 1000);
    }
  }, [activeTab]);

  const recommandationContainerRef = useRef(null);
  const recommandationInfoRef = useRef(null);

  useEffect(() => {
    if (show) {
      recommandationContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    recommandationInfoRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div ref={recommandationContainerRef}>
      <div className="tab-content-title">
        <h2>
          Recommendations <span className="fs-4"> {fromYear.item}</span>
        </h2>
        {showBtn && (
          <button className="btn btn-primary" onClick={() => setShow(!show)}>
            Notes and Sources
          </button>
        )}
      </div>
      <div className="fleet-chart-container h-auto">
        {Object.keys(data).length === 0 && (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Loading loading={Object.keys(data).length === 0} />
          </div>
        )}
        {Object.keys(data).length > 0 && (
          <div className="table-responsive">
            <table className="table-recommandation">
              <thead>
                <tr className="cursor-pointer">
                  <th onClick={() => requestSort("vessel")}>
                    <span className="mr-sm">Vessel{" "}</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                        sortConfig.key === "vessel"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("CII")}>
                    <span className="mr-sm">CII{" "}</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                        sortConfig.key === "CII"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("speed")}>
                    <span className="mr-sm">Speed</span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                        sortConfig.key === "speed"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("fuel")}>
                    <span className="mr-sm">Fuel Origin{" "}</span> 
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                        sortConfig.key === "fuel"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                  <th onClick={() => requestSort("eufm")}>
                    <span className="mr-sm">EUFM Credit{" "} </span>
                    <img
                      src={
                        sortConfig.direction === "asc" &&
                        sortConfig.key === "eufm"
                          ? UpArrowIcon
                          : DownArrowIcon
                      }
                      alt=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => {
                  let smallestMinSpeed = speedDataset[0][0]?.minSpeed;
                  let largestMaxSpeed = speedDataset[0][0]?.maxSpeed;

                  speedDataset.forEach((i) => {
                    i.forEach((speed) => {
                      if (speed.minSpeed < smallestMinSpeed) {
                        smallestMinSpeed = speed.minSpeed;
                      }
                      if (speed.maxSpeed > largestMaxSpeed) {
                        largestMaxSpeed = speed.maxSpeed;
                      }
                    });
                  });
                  return (
                    <tr key={index + 5}>
                      <td>{item.vessel}</td>
                      <td className="d-flex justify-content-center">
                        <div
                          className={`cii-data`}
                          style={{ backgroundColor: item.bg }}
                        >
                          <p className="cii-designation"> {item.CII}</p>
                        </div>
                      </td>
                      <td>
                        {item.speed.map((speed, i) => {
                          const totalMinMax =
                            largestMaxSpeed - smallestMinSpeed;
                          let left = speed.minSpeed - smallestMinSpeed;
                          left = (left / totalMinMax) * 100;

                          let leftOptimalSpeed;
                          let leftTargetSpeed;

                          const roundedOptimalSpeed =
                            +speed.optimalSpeed.toFixed(1);

                          leftOptimalSpeed =
                            100 -
                            ((largestMaxSpeed - speed.optimalSpeed) /
                              totalMinMax) *
                              100;

                          leftOptimalSpeed = `calc(${Math.abs(
                            leftOptimalSpeed
                          )}% - .75em)`;

                          leftTargetSpeed =
                            100 -
                            ((largestMaxSpeed - speed.targetSpeed) /
                              totalMinMax) *
                              100;

                          return (
                            <div className="position-relative" key={index + 3}>
                              <div
                                className="speed-main-container"
                                style={{
                                  marginLeft: `${left}%`,
                                  width: `${
                                    ((speed.maxSpeed - speed.minSpeed) /
                                      totalMinMax) *
                                    100
                                  }%`,
                                }}
                              >
                                <CustomTooltip
                                  tooltipText={`Min Speed: ${speed.minSpeed}`}
                                  placement="top"
                                >
                                  <div className="min-speed"></div>
                                </CustomTooltip>
                                <CustomTooltip
                                  tooltipText={`Optimal Speed: ${roundedOptimalSpeed}`}
                                  placement="top"
                                >
                                  <div
                                    className={`optimal-speed ${
                                      animate ? "slide-animation" : ""
                                    }`}
                                    style={{
                                      left: leftOptimalSpeed,
                                      backgroundColor: `${speed.celerityColor}`,
                                    }}
                                  ></div>
                                </CustomTooltip>
                                <CustomTooltip
                                  tooltipText={`Target Speed: ${speed.targetSpeed}`}
                                  placement="top"
                                >
                                  <div
                                    className="targated-speed"
                                    style={{
                                      left: `${leftTargetSpeed}%`,
                                    }}
                                  ></div>
                                </CustomTooltip>
                                <CustomTooltip
                                  tooltipText={`Max Speed: ${speed.maxSpeed}`}
                                  placement="top"
                                >
                                  <div
                                    className="max-speed"
                                    style={{
                                      left: `${
                                        100 -
                                        ((largestMaxSpeed - speed.maxSpeed) /
                                          totalMinMax) *
                                          100
                                      }%`,
                                    }}
                                  ></div>
                                </CustomTooltip>
                              </div>
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        <div className="fuel-container">
                          {item?.fuel.map((p, i) => {
                            const totalData = fuelMixDataset[index].reduce(
                              (total, p) => total + p.data,
                              0
                            );

                            let percentage = (p.data / totalData) * 70;
                            const tooltip = (p.data * 100).toFixed(0);

                            return (
                              <React.Fragment key={p.data}>
                                <CustomTooltip
                                  tooltipText={`${p.label}: ${formatNumber(
                                    tooltip
                                  )}%`}
                                  placement="top"
                                >
                                  <div
                                    className="fuel-bg"
                                    style={{
                                      width: `${percentage}%`,
                                      backgroundColor: p.backgroundColor,
                                      marginRight: "1px",
                                    }}
                                  ></div>
                                </CustomTooltip>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </td>
                      <td className="p-0">
                        {item?.eufm.map((e, i) => {
                          const divWidth = (e.miniKPIlength * 100).toFixed(0);
                          return (
                            
                              <div className="row w-100" key={i + 1}>
                                <div className="col-6 p-0">
                                  <div className="eufm-chart">
                                    {e.fmcredit_mtCO2eq < 0 && (
                                      <div className="d-flex justify-content-end">
                                        <CustomTooltip
                                          tooltipText={`FM credit: ${formatNumber(
                                            e.fmcredit_mtCO2eq
                                          )} [${eufmData.fmcreditsUnits}]`}
                                          placement="top"
                                        >
                                          <div
                                            className={`draw-box`}
                                            // className={`draw-box ${
                                            //   animate ? "slide-animation" : ""
                                            // }`}
                                            style={{ width: `${-divWidth}%` }}
                                          ></div>
                                        </CustomTooltip>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-6 p-0">
                                  {e.fmcredit_mtCO2eq > 0 && (
                                    <div className="d-flex justify-content-start">
                                      <CustomTooltip
                                        tooltipText={`FM credit: ${formatNumber(
                                          e.fmcredit_mtCO2eq
                                        )} [${eufmData.fmcreditsUnits}]`}
                                        placement="top"
                                      >
                                        <div
                                          className={`draw-box-2`}
                                          // className={`draw-box-2 ${
                                          //   animate ? "slide-animation-new" : ""
                                          // }`}
                                          style={{
                                            width: `${divWidth}%`,
                                          }}
                                        ></div>
                                      </CustomTooltip>
                                    </div>
                                  )}
                                </div>
                              </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <InfoModal
        ref={recommandationInfoRef}
        show={show}
        handleClose={handleClose}
        data={
          data?.mySpeedResults?.comments
            ? data?.mySpeedResults?.comments
            : "No data"
        }
      />
    </div>
  );
};

export default RecommendationTab;
	