import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/Navbar";
import CiiChart from "../components/charts/CiiChart";
import EtsTab from "../components/charts/EtsTab";
import IMOTab from "../components/charts/IMOTab";
import EuFmTab from "../components/charts/EuFmTab";
import FuelTab from "../components/charts/FuelTab";
import RecommendationTab from "../components/charts/RecommendationTab";

import BackArrow from "../assets/arrow-left.svg";
import { requestResult, yearWiseFleetResult } from "../api/data";
import { toast } from "react-toastify";
import EmissionTab from "../components/charts/EmissionTab";
import { setPdfData } from "../redux/reducers/pdfData";
import Pdf from "../components/Pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import ErrorOverlaymodal from "../components/ErrorOverlayModal";

const FleetWideResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedVesselItems = useSelector((state) => state.selectedVesselItems);
  const { fromYear, toYear, priceScenario, legislativeScenario, isPartialYear } = useSelector(
    (state) => state.selectedYearItem
  );
  const chartData = useSelector((state) => state.pdfData);

  const [isActive, setIsAcive] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [caseId, setCaseId] = useState(false);
  const [showPdfData, setShowPdfData] = useState(false);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const tabs = [
    {
      id: 1,
      label: "Recommendations",
      content: <RecommendationTab activeTab={isActive} />,
    },
    {
      id: 2,
      label: `CII`,
      content: <CiiChart activeTab={isActive} />,
    },
    {
      id: 3,
      label: `ETS`,
      content: <EtsTab activeTab={isActive} />,
    },
    {
      id: 4,
      label: `IMO`,
      content: <IMOTab activeTab={isActive} />,
    },
    {
      id: 5,
      label: "EU.FM",
      content: <EuFmTab activeTab={isActive} />,
    },
    {
      id: 6,
      label: "Emissions",
      content: <EmissionTab activeTab={isActive} />,
    },
    {
      id: 7,
      label: "Fuel",
      content: <FuelTab activeTab={isActive} />,
    }
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tab) => {
    setActiveTab(tab.id);
    setIsAcive(tab.id);
  };

  const getYearWiseFleetResult = () => {
    const transformedData = {
      feSpecs: {},
      fromYear: fromYear.item,
      toYear: fromYear.item,
      legislativeScenario: legislativeScenario.id,
      pricingScenario: priceScenario.id,
      partYearMode: isPartialYear,
    };
    if (selectedVesselItems) {
      Object.entries(selectedVesselItems).forEach(([key, value]) => {
        transformedData.feSpecs[key] = {
          vesselId: value.vesselId,
          routeId: value.routeId,
          ciiTarget: value.ciiScore,
          revenue: value.revenue,
        };
      });
    }

    yearWiseFleetResult(transformedData)
      .then((result) => {
        setCaseId(result.data?.caseId);
        dispatch(setPdfData(result.data));
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("Year wise fleet wide result error", err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  useEffect(() => {
    if (fromYear.id === null || toYear.id === null) {
      navigate(-1);
      toast.error("Please select year to show result", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        toastId: "result",
      });
    } else {
      getYearWiseFleetResult();
    }
  }, []);

  const user = localStorage.getItem("userEmail");

  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const componentRef4 = useRef();
  const componentRef5 = useRef();
  const componentRef6 = useRef();
  const componentRef7 = useRef();

  const handleFileShare = () => {
    setShowPdfData(true);
    setIsDownloading(true);
    const chartContainer1 = componentRef1.current;
    const chartContainer2 = componentRef2.current;
    const chartContainer3 = componentRef3.current;
    const chartContainer4 = componentRef4.current;
    const chartContainer5 = componentRef5.current;
    const chartContainer6 = componentRef6.current;
    const chartContainer7 = componentRef7.current;

    chartContainer5.classList.add('pdf-export');

    setTimeout(async () => {
      const chart1 = await html2canvas(chartContainer1, { scale: 1.25 });
      const chart2 = await html2canvas(chartContainer2, { scale: 1.25 });
      const chart3 = await html2canvas(chartContainer3, { scale: 1.25 });
      const chart4 = await html2canvas(chartContainer4, { scale: 1.25 });
      const chart5 = await html2canvas(chartContainer5, { scale: 1.25 });
      const chart6 = await html2canvas(chartContainer6, { scale: 1.25 });
      const chart7 = await html2canvas(chartContainer7, { scale: 1.25 });

      var pdfWidth = 300;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a3",
        compress: true,
      });

      const marginLeft = 20;
      const marginTop = 20;

      const addImageToPdf = (chart, isLastChart) => {
        const imgData = chart.toDataURL("image/png");
        let imgWidth = pdfWidth - marginLeft * 2;
        let imgHeight = (chart.height * imgWidth) / chart.width;
        const canvasAspectRatio = chart.width / chart.height;
        const pdfAspectRatio = imgWidth / imgHeight;
        if (canvasAspectRatio < pdfAspectRatio) {
          imgHeight = imgWidth / canvasAspectRatio;
        } else {
          imgWidth = imgHeight * canvasAspectRatio;
        }

        pdf.addImage(
          imgData,
          "PNG",
          marginLeft,
          marginTop,
          imgWidth,
          imgHeight
        );
        if (!isLastChart) {
          pdf.addPage();
        }
      };

      addImageToPdf(chart7);  // Move the recommendation tab on top
      addImageToPdf(chart1);
      addImageToPdf(chart2);
      addImageToPdf(chart3);
      addImageToPdf(chart4);
      addImageToPdf(chart5, true);
      addImageToPdf(chart6)

      // pdf.save(`Report#${caseId} .pdf`);
      const pdfBlob = pdf.output("blob", {
        filename: `Report-${caseId}`,
      });
      chartContainer5.classList.remove('pdf-export');

      setShowPdfData(false);

      sendPdfByEmailData(caseId, user, pdfBlob);
    }, 4000);
  };

  const sendPdfByEmailData = (caseId, email, pdfFile) => {
    const formData = new FormData();
    formData.append("file", pdfFile);

    requestResult(caseId, email, formData)
      .then((res) => {
        toast.success(`Report has been sent to ${email}`, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          toastId: "Report-Send",
        });
        setIsDownloading(false);
      })
      .catch((error) => {
        setError(error);
        setOpen(true);
        console.log("error", error);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <>
      <div className="login-container h-100 pb-5">
        <div className="pb-5">
          <Navbar show={true} showfaq={true} showversion={true} />

          <div className="fleet-wide-container">
            <div className="back-btn">
              <button
                className="btn btn-primary add"
                onClick={() => navigate("/")}
              >
                <img src={BackArrow} alt="" />
              </button>
            </div>
            <div className="tab-container">
              <div className="tab-scroll-container">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item back-btn-list" role="presentation">
                    <button
                      className="btn btn-primary add"
                      onClick={() => navigate("/")}
                    >
                      <img src={BackArrow} alt="" />
                    </button>
                  </li>
                  {tabs.map((tab) => (
                    <li
                      className="nav-item"
                      key={tab.id + 1}
                      role="presentation"
                    >
                      <button
                        className={`nav-link ${
                          activeTab === tab.id ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(tab)}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                {tabs.map((tab) => (
                  <div
                    key={tab.id}
                    className={`tab-pane fade ${
                      activeTab === tab.id ? "show active" : ""
                    }`}
                    role="tabpanel"
                  >
                    {tab.content}
                  </div>
                ))}
                {activeTab === 1 && (
                  <div className="send-btn d-flex align-items-center mt-3 justify-content-center">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={() => handleFileShare()}
                      disabled={
                        Object.keys(chartData).length === 0 || isDownloading
                      }
                    >
                      {isDownloading ? "Sending" : "Send Report"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.message === "Network Error"
                ? "The system is temporarily out of service. Please contact us if this error persists."
                : error.response.data.message
              : "The system is temporarily out of service. Please contact us if this error persists."
            : "No error"
        }
      />
      <Pdf
        showPdfData={showPdfData}
        componentRef1={componentRef1}
        componentRef2={componentRef2}
        componentRef3={componentRef3}
        componentRef4={componentRef4}
        componentRef5={componentRef5}
        componentRef6={componentRef6}
        componentRef7={componentRef7}
      />
    </>
  );
};

export default FleetWideResult;
