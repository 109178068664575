import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../components/Navbar";
import AssignRouteModal from "../components/AssignRouteModal";
import EditVesselModal from "../components/EditVesselModal";

import "../css/responsive.css";

import EditBtnIcon from "../assets/editbtn.svg";
import AssignRouteNew from "../assets/assignRouteNew.svg"
import FailCompliance from "../assets/failcompliance.svg"
import SuccessCompliance from "../assets/successCompliance.svg"
import ErrorOverlaymodal from "../components/ErrorOverlayModal";
import useNumericInput from "../hooks/useNumericInput";

import {
  allRoutes,
  allMainEngines,
  allAuxEngines,
  allBoilerType,
  allFuelTypes,
  allVesselTypes,
  routePatterns,
  routeKPIs,
  allVesselDto,
  changeRoute,
  getAllYears,
  allPriceForecastScenario,
  allLegislativeScenario,
  changeRevenue,
  allRevenueByOwner,
  getOwner,
  emissionsInventoryByOwner,
  changeEmissions,
} from "../api/data";

import { formatNumber } from "../utils/formatter";
import SelectBox from "../components/SelectBox";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { setSelectedVesselItems } from "../redux/reducers/selectedVesselItemsReducer";
import {
  setFromYear,
  setLegislativeScenario,
  setPriceScenario,
  setToYear,
  setIsPartialYear,
  setSelectAll
} from "../redux/reducers/selectedYearSlice";
import { setPermissions } from "../redux/reducers/permissionsSlice";
import { setFreeRevenueValue } from "../redux/reducers/revenueValueSlice";
import { setPdfData } from "../redux/reducers/pdfData";
import { setChecked } from "../redux/reducers/emissionSwitchChecked";
import { setSelctedPurchaseBy } from "../redux/reducers/fuelPurchaseBy";
import CustomTooltip from "../components/CustomTooltip";
import { Button } from "react-bootstrap";
import { ref } from "yup";
export const CIISCORE = [
  { id: 1, ciiscore: "A", color: "#7CDBA7" },
  { id: 2, ciiscore: "B", color: "#B1DF80" },
  { id: 3, ciiscore: "C", color: "#F8C562" },
  { id: 4, ciiscore: "D", color: "#FF9447" },
  { id: 5, ciiscore: "E", color: "#FF5B5B" },
];



const vesselsDefaultImage =
  "https://storage.googleapis.com/vessel-images/default_vessel_image.jpg";

const Home = () => {
  const navigate = useNavigate();

  const permissions = useSelector((state) => state.permissions.permissions);

  const [vesselDto, setVesselDto] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [route, setRoutes] = useState([]);
  const [loadingRouteData, setLoadingRouteData] = useState(false);
  const [showAssignRoute, setShowAssignRoute] = useState(false);

  const [showEditVesselModal, setShowEditVesselModal] = useState(false);
  const [listMe, setListMe] = useState([]);
  const [listAux, setListAux] = useState([]);
  const [listBoiler, setListBoiler] = useState([]);
  const [listFuelType, setListFuelType] = useState([]);
  const [listVesselType, setListVesselType] = useState([]);
  const [item, setItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  // all data
  const [auxEnginData, setAuxEngineData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [showError, setShowError] = useState("");

  const [speedMin, setSpeedMin] = useState(10);
  const [speedMax, setSpeedMax] = useState(15);
  const [selectSpeed, setSelectSpeed] = useState(12);

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [endMarker, setEndMarkers] = useState([]);
  const [loadingMap, setLoadingMap] = useState(false);
  const [paths, setPaths] = useState([]);
  const [routePattern, setRoutePattern] = useState(null);
  const [showYearError, setShowYearError] = useState(false);
  const [years, setYears] = useState([]);
  const [revenueValue, setRevenueValue] = useState(null);
  const [showRevenueModal, setShowRevenueModal] = useState(null);
  const [revenue, setRevenue] = useState([]);
  const [emissionInventory, setEmissionInventory] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState(null);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [emissionToDate, setEmissionToDate] = useState("");
  const [globalEmissionToDate, setGlobalEmissionToDate] = useState("");
  const [globalWorkToDate, setGlobalWorkToDate] = useState("");
  const [energyToDate, setEnergyToDate] = useState("");
  const [emissionError, setEmissionError] = useState("");
  const [globalEmissionError, setGlobalEmissionError] = useState("");
  const [globalWorkError, setGlobalWorkError] = useState("");
  const [energyError, setEnergyError] = useState("");
  const { handleOnKeyDown } = useNumericInput();
  const [emissionItemId, setEmissionItemId] = useState(null);
  

  const { fromYear, toYear, priceScenario, legislativeScenario, isPartialYear, selectAll } = useSelector(
    (state) => state.selectedYearItem
  );

  const handleFromYearChange = (item) => {
    dispatch(setFromYear(item));
    dispatch(setToYear(item));
  };

  const handlePriceScenarioChange = (item) => {
    dispatch(setPriceScenario(item));
  };

  const handleLegislativeScenarioChange = (item) => {
    dispatch(setLegislativeScenario(item));
  };

  const [showPriceForecastError, setShowPriceForecastError] = useState(false);
  const [priceForecastScenarios, setPriceForecastScenarios] = useState([]);
  const [showLegislativeError, setShowLegislativeError] = useState(false);
  const [legislativeScenarios, setLegislativeScenarios] = useState([]);

  const [selectedVessel, setSelectedVessel] = useState(null);
  const [routeKpiData, setRouteKpiData] = useState(null);
  const [loadingRouteKpi, setLoadingRouteKpi] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const selectedVesselItems = useSelector((state) => state.selectedVesselItems);
  const revenueValues = useSelector((state) => state.revenueValue);
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const canAccessRoute = (requiredPermission) => {
    const today = new Date();
    return permissions.some((permission) => {
      const permissionDate = new Date(permission.expirationDate);
      return (
        permission.licensePermission.permissionName === requiredPermission &&
        permissionDate >= today
      );
    });
  };

  const hasEditRoutesPermission = canAccessRoute("EditRoutes");
  const hasEditVesselsPermission = canAccessRoute("EditVessels");
  const hasEditPermission = hasEditRoutesPermission && hasEditVesselsPermission;

  const getUpdatedItem = (item, additionalValues = {}) => {
    const isEmpty = Object.keys(revenueValues).length === 0;
    const hasEditRoutesPermission = canAccessRoute("EditRoutes");
    const hasEditVesselsPermission = canAccessRoute("EditVessels");
    const hasEditPermission =
      hasEditRoutesPermission && hasEditVesselsPermission;
    let revenueValue;
    if (hasEditPermission) {
      revenueValue = item?.myRevenueEntry?.revenueRTUSD;
    } else if (!isEmpty) {
      revenueValue = revenueValues[item.id]?.revenueValue;
    } else if (selectedVesselItems[item.id]?.revenue) {
      revenueValue = selectedVesselItems[item.id].revenue;
    } else {
      revenueValue = item?.myRevenueEntry?.revenueRTUSD;
    }

    return {
      vesselId: item.id,
      ciiScore:
        additionalValues.ciiScore ||
        selectedVesselItems[item.id]?.ciiScore ||
        "C",
      revenue: +revenueValue,
      routeId:
        selectedVesselItems[item.id]?.routeId ?? item?.myRevenueEntry?.routeId,
      routeName:
        selectedVesselItems[item.id]?.routeName ?? item?.myRouteKPI?.routeName,
      daysTotal:
        selectedVesselItems[item.id]?.daysTotal ?? item?.myRouteKPI?.daysTotal,
      portDaysTotal:
        selectedVesselItems[item.id]?.portDaysTotal ??
        item?.myRouteKPI?.portDaysTotal,
      nmTotal:
        selectedVesselItems[item.id]?.nmTotal ?? item?.myRouteKPI?.nmTotal,
      yearlyRt:
        selectedVesselItems[item.id]?.yearlyRt ?? item?.myRouteKPI?.yearlyRt,
      eu_ptg: selectedVesselItems[item.id]?.eu_ptg ?? item?.myRouteKPI?.eu_ptg,

      ...additionalValues,
    };
  };

  const handleSelectedVessel = (item, e) => {
    e.stopPropagation();
    const checked = e.target.checked;
    const updatedItems = { ...selectedVesselItems };

    if (checked) {
      updatedItems[item.id] = getUpdatedItem(item);
    } else {
      delete updatedItems[item.id];
    }

    dispatch(setSelectedVesselItems(updatedItems));

    const allSelected = vesselDto.every(
      (vessel) => updatedItems[vessel.id]
    );
    dispatch(setSelectAll(allSelected));
  };

  const handleSelectCiiScore = (item, e) => {
    const updatedItem = getUpdatedItem(item, { ciiScore: e.target.value });
    handleSelectedVessselUpdatedItems(item.id, updatedItem);
  };

  const handleSelectedVessselUpdatedItems = (itemId, updatedItem) => {
    const updatedItems = {
      ...selectedVesselItems,
      [itemId]: updatedItem,
    };
    dispatch(setSelectedVesselItems(updatedItems));
  };
  const selectedVesselDetails = Object.values(selectedVesselItems);

  const handleRoute = (item) => {
    if (item.id) {
      getRouteMapPatterns(item.id);
    }
    setSelectedRoute(item.id);
    setShowError("");
    getRouteKpi(item.id, selectSpeed);
  };

  const changeSpeed = (event) => {
    event.stopPropagation();

    const {
      target: { value },
    } = event;
    setSelectSpeed(parseFloat(value));
    getRouteKpi(selectedRoute, value);
  };

  const handleRouteSave = async (e) => {
    if (selectedRoute === null) {
      setShowError("Please select route");
    } else {
      setShowLoading(true);

      const hasEditRoutesPermission = canAccessRoute("EditRoutes");
      const hasEditVesselsPermission = canAccessRoute("EditVessels");

      if (hasEditRoutesPermission && hasEditVesselsPermission) {
        const res = await changeRoute(selectedVessel.id, selectedRoute);
        await getAllVesselDto();
      } else {
        await getRouteKpi(selectedRoute, selectSpeed);
      }
      const findRevenue = revenue.find(
        (r) => r.vesselId === selectedVessel?.id && r.routeId === selectedRoute
      );
      const updatedItem = getUpdatedItem(selectedVessel, {
        routeId: selectedRoute,
        daysTotal: routeKpiData.daysTotal,
        routeName: routeKpiData.routeName,
        portDaysTotal: routeKpiData.portDaysTotal,
        nmTotal: routeKpiData.nmTotal,
        yearlyRt: hasEditPermission
          ? item?.myRouteKPI?.yearlyRt
          : routeKpiData.yearlyRt,
        eu_ptg: routeKpiData.eu_ptg,
        ciiScore: !selectedVesselItems[selectedVessel.id]
          ? "C"
          : selectedVesselItems[selectedVessel.id].ciiScore,
        revenue: findRevenue?.revenueRTUSD,
      });
      handleSelectedVessselUpdatedItems(selectedVessel.id, updatedItem);
      setShowLoading(false);
      setShowAssignRoute(false);
      setSelectedRoute(selectedRoute);
    }
  };

  const showRevenue = (e, item) => {
    e.stopPropagation();
    if (emissionItemId === null) {
      setEmissionItemId(item?.id);
      setGlobalEmissionToDate(emissionInventory?.[item.id]?.global_emissionsToDate_mtCO2eq.toFixed(1) || null);
      setGlobalWorkToDate(emissionInventory?.[item.id]?.global_workToDate_mtNm.toFixed(1) || null);
      setEmissionToDate(emissionInventory?.[item.id]?.eu_emissionsToDate_mtCO2eq.toFixed(1) || null);
      setEnergyToDate(emissionInventory?.[item.id]?.eu_energyToDate_GJ.toFixed(1) || null);
      setEmissionError("");
      setGlobalEmissionError("");
      setEnergyError("");
      setGlobalWorkError("");
      setShowRevenueModal(item.id);
    } else if (emissionItemId === item?.id) {
      setShowRevenueModal(null);
      setEmissionItemId(null);
    } else {
      setEmissionItemId(item?.id)
      setGlobalEmissionToDate(emissionInventory?.[item.id]?.global_emissionsToDate_mtCO2eq.toFixed(1) || null);
      setGlobalWorkToDate(emissionInventory?.[item.id]?.global_workToDate_mtNm.toFixed(1) || null);
      setEmissionToDate(emissionInventory?.[item.id]?.eu_emissionsToDate_mtCO2eq.toFixed(1) || null);
      setEnergyToDate(emissionInventory?.[item.id]?.eu_energyToDate_GJ.toFixed(1) || null);
      setEmissionError("");
      setGlobalEmissionError("");
      setEnergyError("");
      setGlobalWorkError("");
      setShowRevenueModal(item.id);
    }
    
  };

  const handleAddRevenue = (e, item) => {
    e.stopPropagation();
    const hasEditRoutesPermission = canAccessRoute("EditRoutes");
    const hasEditVesselsPermission = canAccessRoute("EditVessels");
    const hasEditPermission =
      hasEditRoutesPermission && hasEditVesselsPermission;

    const getRouteId = (item) =>
      selectedVesselItems[item.id]?.routeId ?? item.myRevenueEntry?.routeId;
    const getRouteKPIValue = (key, item) =>
      hasEditPermission
        ? item?.myRouteKPI?.[key]
        : selectedVesselItems[item.id]?.[key] ?? item?.myRouteKPI?.[key];

    if (hasEditPermission && revenueValue) {
      changeRevenue(item?.id, item?.myRevenueEntry.routeId, revenueValue)
        .then(() => {
          setRevenueValue(null);
          getRevenueByOwner();
          setShowRevenueModal(null);
          getAllVesselDto();
        })
        .catch((err) => {
          setError(err);
          setOpen(true);
          console.error("Change revenue err", err);
        });
    } else {
      console.log("no update");
    }

    const updatedSelectedItem = getUpdatedItem(item, {
      revenue: revenueValue,
    });

    handleSelectedVessselUpdatedItems(item.id, updatedSelectedItem);
    setRevenueValue(null);
    setShowRevenueModal(null);
  };

  const getRevenueByOwner = async () => {
    try {
      const result = await allRevenueByOwner();
      setRevenue(result.data);
    } catch (error) {
      setError(error);
      setOpen(true);
      console.error("Get revenue by owner error", error);
    }
  };

  const getEmissionsInventoryByOwner = async () => {
    try {
      const result = await emissionsInventoryByOwner();
      setEmissionInventory(result.data);

    } catch (error) {
      setError(error);
      setOpen(true);
    }
  }

  const handleCheckedPartialYear = (e) => {
    if (!isPartialYear) {
      emissionsInventoryByOwner()
        .then((result) => {
          setEmissionInventory(result.data);
        })
        .catch((error) => {
          setError(error);
          setOpen(true);
        }) 
    }
  }

  const handleChangeEmissions = (e, item) => {
    e.stopPropagation();
    const currentDate = new Date();
    const date = new Date(currentDate).toISOString().split('T')[0];

    const fields = [
      { name: "Global Emission to Date", value: globalEmissionToDate },
      { name: "Global Work to Date", value: globalWorkToDate },
      { name: "Emission to Date", value: emissionToDate },
      { name: "Energy to Date", value: energyToDate },
    ];

    let errorMessage = "";

    
    let allDecimal = fields.every(field => field.value === ".")
    if (allDecimal) {
      errorMessage = "Please Enter valid Year-to-Date Values";
    } else {
      fields.forEach(field => {
        if (field.value === ".") {
          errorMessage += `${field.name} should be a valid emission value. `;
        }
      });
    }
    if (errorMessage) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "result",
        });
      } else {
      if (!globalEmissionToDate || !globalWorkToDate || !emissionToDate || !energyToDate) {
        toast.error("Please Enter all Year-to-Date Values", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "result",
        });
      } else {
          changeEmissions(item?.id, date, emissionToDate, globalEmissionToDate, globalWorkToDate, energyToDate)
            .then((response) => {
              const {
              eu_emissionsToDate_mtCO2eq,
              eu_energyToDate_GJ,
              global_emissionsToDate_mtCO2eq,
              global_workToDate_mtNm
            } = response;
    
            (setEmissionToDate(eu_emissionsToDate_mtCO2eq || null));
            (setGlobalEmissionToDate(global_emissionsToDate_mtCO2eq || null));
            (setGlobalWorkToDate(global_workToDate_mtNm || null));
            (setEnergyToDate(eu_energyToDate_GJ || null));
            setShowRevenueModal(null);
            getEmissionsInventoryByOwner();
            toast.success("Your changes have been saved", {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "updated_refine_data",
            });
          })
            .catch((err) => {
              setError(err);
              setOpen(true);
            });
      }
    }
  }

  const defaultProps = {
    center: {
      lat: 61.344078,
      lng: -17.578125,
    },
    zoom: 0,
  };

  const handleEditVessel = (data) => {
    setShowEditVesselModal(true);
    setItem(data);
    setIsEdit(true);
  };

  const handleAssignRoute = (item) => {
    const selectedRoute = item.selectedRoute;
    const findSelectedRoute = routeData.find(
      (route) => route.routePatternName === selectedRoute
    );

    setSelectedVessel(item);
    setSpeedMax(item.maxSpeed);
    setSpeedMin(item.minSpeed);
    setShowAssignRoute(true);
    if (selectedVesselItems[item.id]?.routeId) {
      setSelectedRoute(selectedVesselItems[item.id]?.routeId);
    } else {
      setSelectedRoute(findSelectedRoute?.id);
    }

    if (
      !selectSpeed ||
      selectSpeed > item.maxSpeed ||
      selectSpeed < item.minSpeed
    )
      setSelectSpeed(item.minSpeed);
  };

  const getAllSelectYears = () => {
    getAllYears()
      .then((result) => {
        const yearsData = result.data.map((year, index) => ({
          id: index + 1,
          item: year,
        }));
        setYears(yearsData);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err get all year", err);
      });
  };

  const getAllVesselDto = () => {
    allVesselDto()
      .then((res) => {
        setVesselDto(res.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err vessel dto", err);
      });
  };

  const getAllRoutes = () => {
    setLoadingRouteData(true);

    allRoutes()
      .then((result) => {
        setRouteData(result.data);
        setRoutes(result.data);
        setLoadingRouteData(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err all routes", err);
      });
  };

  const getAllMainEngines = () => {
    allMainEngines()
      .then((result) => {
        const data = result.data;
        const list = Object.keys(data).map((key) => ({
          id: data[key].id,
          item: data[key].estname,
        }));
        setListMe(list);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err main engines", err);
      });
  };

  const getAllAuxEngine = () => {
    allAuxEngines()
      .then((result) => {
        setAuxEngineData(result.data);
        const data = result.data;
        const list = Object.keys(data).map((key) => ({
          id: data[key].id,
          item: data[key].estname,
        }));
        setListAux(list);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err aux engines", err);
      });
  };

  const getAllBoilerType = () => {
    allBoilerType()
      .then((result) => {
        const data = result.data;
        const list = Object.keys(data).map((key) => ({
          id: data[key].id,
          item: data[key].estname,
        }));
        setListBoiler(list);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err boiler type", err);
      });
  };

  const getAllFuelType = () => {
    allFuelTypes()
      .then((result) => {
        const data = result.data.filter((item) => item.visibleInFE === true);
        setFuelData(data);
        const list = data.map((item) => ({
          id: item.id,
          item: item.fuelFamilyName,
        }));
        setListFuelType(list);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err fuel type", err);
      });
  };

  const getAllVesselTypes = () => {
    allVesselTypes()
      .then((result) => {
        const data = result.data;
        const list = data.map((item) => ({
          id: item.id,
          item: `${item.vesselTypeName} - ${item?.family?.vesselFamilyName}`,
        }));
        setListVesselType(list);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err vessel type", err);
      });
  };

  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
        dispatch(setPermissions(result.data.owner.permissions));
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("err get company name", err);
      });
  };

  const getRouteMapPatterns = async (routeId) => {
    await routePatterns(routeId)
      .then((response) => {
        setRoutePattern(response.data);
      })
      .catch((error) => {
        setError(error);
        setOpen(true);
        console.error("getRoutePatterns error", error);
      });
  };

  const getRouteKpi = (routeId, speed) => {
    if (routeData && routeData.length > 0) {
      let index = routeData.findIndex((obj) => obj.id === routeId);

      if (index !== -1 && routeId && speed) {
        setLoadingRouteKpi(true);
        routeKPIs(routeId, speed)
          .then((res) => {
            setRouteKpiData(res.data);
            setLoadingRouteKpi(false);
            const updatedRouteData = [...routeData];
            updatedRouteData[index].routeKpiData = res.data;
            setRouteData(updatedRouteData);
          })
          .catch((err) => {
            setError(err);
            setOpen(true);
            console.error("route kpi err", err);
          });
      }
    }
  };

  const getAllPriceForecastScenario = () => {
    allPriceForecastScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setPriceForecastScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("error in price forcast scenario", err);
      });
  };

  const getAllLegislativeScenario = () => {
    allLegislativeScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setLegislativeScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.error("error in price forcast scenario", err);
      });
  };

  useEffect(() => {
    if (route.length > 0) {
      routeData.forEach((r) => getRouteKpi(r.id, selectSpeed));
    }
  }, [route]);

  async function setMapData() {
    if (routePattern) {
      paths.forEach((e) => {
        e.setMap(null);
      });

      if (markers.length) {
        markers.forEach((e) => e.setMap(null));
      }
      if (endMarker.length) {
        endMarker.forEach((e) => e.setMap(null));
      }
      var tempMarkers = [];

      routePattern.segments.forEach((segment) => {
        if (segment.geometry === null) {
          console.log("Vessel Not Moving");
        } else if (segment.geometry) {
          let parsedGeometry = JSON.parse(segment.geometry);
          const flightPlanCoordinates = parsedGeometry.map((e) => ({
            lat: e[1],
            lng: e[0],
          }));

          const marker = new window.google.maps.Marker({
            map: map,
            position: {
              lat: parsedGeometry[0][1],
              lng: parsedGeometry[0][0],
            },
          });

          tempMarkers.push(marker);
          setMarkers((state) => [...state, marker]);

          const lastElement = parsedGeometry.pop();

          const markerEnd = new window.google.maps.Marker({
            map: map,
            position: {
              lat: lastElement[1],
              lng: lastElement[0],
            },
          });

          setEndMarkers((state) => [...state, markerEnd]);

          const lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 4,
          };

          const flightPath = new window.google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: "#FFFFFF",
            strokeOpacity: 1.0,
            strokeWeight: 0,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ],
          });

          const bounds = new window.google.maps.LatLngBounds();

          flightPlanCoordinates.forEach((coordinate) => {
            bounds.extend(
              new window.google.maps.LatLng(coordinate.lat, coordinate.lng)
            );
          });
          setLoadingMap(true);
          map.panToBounds(bounds);
          const center = bounds.getCenter();
          map.setCenter(center);
          setTimeout(() => {
            setLoadingMap(false);
          }, 1000);

          flightPath.setMap(map);
          setPaths((state) => [...state, flightPath]);
        }
      });
      if (!loadingRouteData && !loadingRouteKpi) {
        setLoadingMap(false);
      }
    }
  }

  useEffect(() => {
    getAllRoutes();
    getAllMainEngines();
    getAllAuxEngine();
    getAllBoilerType();
    getAllFuelType();
    getAllVesselTypes();
    getOwnerPermission();
    getAllVesselDto();
    getAllSelectYears();
    getAllPriceForecastScenario();
    getAllLegislativeScenario();
    getRevenueByOwner();
  }, []);

  useEffect(() => {
    if (!map) return;
    setMapData();
  }, [map]);

  useEffect(() => {
    if (!routePattern && !routePattern?.segments) return;
    setMapData();
  }, [routePattern]);

  useEffect(() => {
    if (map && selectedVessel) {
      const selectedRoute = selectedVessel?.myRevenueEntry?.routeId;
      const findSelectedRoute = routeData.find(
        (route) => route.id === selectedRoute
      );
      getRouteMapPatterns(findSelectedRoute?.id);
    }
  }, [map, selectedVessel]);

  useEffect(() => {
    if (isPartialYear) {
      getEmissionsInventoryByOwner();
    } else {
      setShowRevenueModal(false);
    }
  }, [isPartialYear]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowRevenueModal(null);
        setEmissionItemId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setShowRevenueModal]);

  const handleOptimization = () => {
    dispatch(setPdfData({}));
    dispatch(setChecked(true));
    dispatch(setSelctedPurchaseBy({ id: 1, unit: "Expense [USD]" }));
    const isEmpty = Object.keys(selectedVesselItems).length === 0;
    const validationMessages = [];
    if (isEmpty) {
      validationMessages.push("Please select vessel to proceed.");
    } else if (priceScenario.id === null) {
      validationMessages.push(
        "Please select a fuel pricing scenario to proceed."
      );
    } else if (legislativeScenario.id === null) {
      validationMessages.push(
        "Please select a legislative scenario to proceed."
      );
    } else if (fromYear.id === null) {
      validationMessages.push("Please select target year to proceed.");
    } else if (toYear.id === null) {
      validationMessages.push("Please select end year to proceed.");
    } else if (fromYear.item !== toYear.item) {
      validationMessages.push(
        "Multi-year optimization is coming soon (Q2 2024). Please select only one year."
      );
    }

    if (validationMessages.length > 0) {
      toast.error(validationMessages.join(" "), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        toastId: "result",
      });
    } else {
      window.scrollTo(0, 0);
      navigate("fleetwideresult");
    }
  };

  const handlEmissionToDateChange = (e) => {
    if (e.target.value === ".") {
      setEmissionError("Please enter valid number");
      setEmissionToDate(e.target.value);
    } else {
      const newValue = e.target.value;

      setEmissionToDate(newValue);
      setEmissionError("");
    }
  }

  const handlEnergyToDateChange = (e) => {
    if (e.target.value === ".") {
      setEnergyError("Please enter valid number");
      setEnergyToDate(e.target.value);
    } else {
      const newValue = e.target.value;

      setEnergyToDate(newValue);
      setEnergyError("");
    }
  }

  const handlGlobalEmissionToDateChange = (e) => {
    if (e.target.value === ".") {
      setGlobalEmissionError("Please enter valid number");
      setGlobalEmissionToDate(e.target.value);
    } else {
      const newValue = e.target.value;
      setGlobalEmissionToDate(newValue);
      setGlobalEmissionError("");
    }
  }

  const handlGlobalWorkToDateChange = (e) => {
    if (e.target.value === ".") {
      setGlobalWorkError("Please enter valid number");
      setGlobalWorkToDate(e.target.value);
    } else {
      const newValue = e.target.value;

      setGlobalWorkToDate(newValue);
      setGlobalWorkError("");
    }
  }


  const handleOnWheel = (event) => {
    event.preventDefault();
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const updatedItems = {};
    if (checked) {
      vesselDto.forEach((item) => {
        updatedItems[item.id] = getUpdatedItem(item);
      });
    } 
    dispatch(setSelectedVesselItems(checked ? updatedItems : {}));
    dispatch(setSelectAll(checked));
  }

  return (
    <>
      <div className="h-100 splash-screen pb-0">
        <Navbar show={true} showfaq={true} showversion={true} />
        {/* <MainContainer /> */}
      </div>
      <div >
      <main className="container-padding">
        <div className="login-container h-100 splash-screen">
          <div className="pos-relative">
            <div className="table-header">
              <span className="span-lg">{ownerInfo?.owner?.name} Vessels</span>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '26px',
              alignSelf: 'stretch'
              }}
                >
            </div>
            <div className="selector-actions-container-v2 d-flex" style={{display: 'flex', justifyContent:'space-between'}}>
              <div style={{display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap', textAlign: 'left'}}>
                <div className="selector-actions w-25">
                  <p className="selector-names"
                  >Price Forecast:
                  </p>
                  <SelectBox
                    selectedItem={priceScenario}
                    listItems={priceForecastScenarios}
                    setSelectedItem={(item) =>
                      handlePriceScenarioChange(item)
                    }
                    disabledOptions={[priceScenario]}
                    showError={showPriceForecastError}
                    setShowError={setShowPriceForecastError}
                    className="select-box-v2"
                  />
                </div>
                <div className="selector-actions w-25">
                  <p className="selector-names">Legislative Scenario:</p>
                  <SelectBox
                    selectedItem={legislativeScenario}
                    listItems={legislativeScenarios}
                    setSelectedItem={(item) =>
                      handleLegislativeScenarioChange(item)
                    }
                    disabledOptions={[legislativeScenario]}
                    showError={showLegislativeError}
                    setShowError={setShowLegislativeError}
                    className="select-box-v2"
                    // selectBoxContainer="selectyear-container"
                  />
                </div>
                <div className="selector-actions">
                  <p className="selector-names">Target Year:</p>
                  <SelectBox
                    show={true}
                    selectedItem={fromYear}
                    listItems={years}
                    setSelectedItem={(item) => handleFromYearChange(item)}
                    disabledOptions={[fromYear, toYear]}
                    showError={showYearError}
                    setShowError={setShowYearError}
                    className="small-select-box"
                    // selectBoxContainer="selectyear-container"
                  />
                  </div>
                  <div className="partial-year d-flex mt-16">
                      <p className="selector-names-year">Select all: </p>
                      <div className="form-check form-switch ms-2">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={selectAll}
                          onChange={(e) => {
                            handleSelectAll(e);
                            }
                          }
                        />
                      </div>
                    </div>
                  {canAccessRoute("PartialYear") && 
                    <div className="partial-year d-flex mt-16">
                      <p className="selector-names-year">Partial Year: </p>
                      <div className="form-check form-switch ms-2">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={isPartialYear}
                          onChange={(e) => {
                            handleCheckedPartialYear(e);
                            dispatch(setIsPartialYear(e.target.checked));
                          }
                          }
                        />
                      </div>
                    </div>
                  }
              </div>
              <div style={{display:'flex', alignItems: 'center', flexWrap: 'wrap', textAlign: 'right'}}>
                <div className="selector-actions" style={{textAlign: 'end'}}> 
                <button
                  className="optimize-button"
                  onClick={() => handleOptimization()}
                  
                >
                  Optimize Fleet
                </button>
                </div>
              </div>
            </div>

            <div
              id="tableShadowContainer"
              className={`${
                vesselDto.length > 0 ? "table-shadow-container" : ""
              }`}
            >
              <div
                id="tableInnerContainer"
                className="table-detail-inner-container"
              >
                {vesselDto.length > 0 ? (
                  vesselDto.map((item, index) => (
                    <div
                      className={`table-content-container ${
                        selectedVesselItems[item.id] ? "selected" : ""
                        }`}
                      style={{flexDirection: 'column'}}
                      key={index + 1}
                    >
                      <div className="d-flex flex-col">
                        <span style={{
                          color: '#2C8EC6',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal'
                        }}>{item?.name}</span >
                      </div>
                    <div style={{gap:'16px', display: 'flex'}}>
                      <figure>
                        <img
                          src={`https://storage.googleapis.com/vessel-images/vessel-image-${item.vesselTypeId}-thumbnail.png`}
                          alt="Vessel Image"
                          onError={(e) => {
                            e.target.src = vesselsDefaultImage;
                          }}
                        />
                      </figure>
                      <div className="vessel-detail-main-container">
                        <div className="vessel-detail-container">
                          <div className="row" style={{textAlign:'left'}}>
                            <div className="col-md-6 col-lg-12 col-xl-6">
                              <div className="row flex-grow-1 mb-2">
                                <div className="col-md-3">
                                  <h4 className="key">Family:</h4>
                                </div>
                                <div className="col-md-9">
                                  <p className="value" style={{textAlign: 'left'}}>{item?.type}</p>
                                </div>
                              </div>
                              <div className="row flex-grow-1 mb-2">
                                <div className="col-md-3">
                                  <h4 className="key">Capacity:</h4>
                                </div>
                                <div className="col-md-9">
                                  <p className="value" style={{textAlign: 'left'}}>
                                    {formatNumber(item?.capacity)}{" "}
                                    {item?.capacityUnits.toUpperCase()}
                                  </p>
                                </div>
                              </div>
                              <div className="row flex-grow-1 mb-2" >
                                <div className="col-md-3" >
                                  <h4 className="key">M/E:</h4>
                                </div>
                                <div className="col-md-9">
                                  <p className="value" style={{textAlign: 'left'}}>
                                    {item?.allEngineTypesOnboardByClass?.me[0]}
                                  </p>
                                </div>
                              </div>
                              <div className="row flex-grow-1 mb-2">
                                <div className="col-md-3">
                                  <h4 className="key" style={{textAlign: 'left'}}>Aux:</h4>
                                </div>
                                <div className="col-md-9">
                                  <ul className="aux-list col-md-9" style={{textAlign: 'left'}}>
                                    {item?.allEngineTypesOnboardByClass?.aux?.map(
                                      (i, index) => (
                                        <li className="value" key={index + 1}>
                                          {item?.otherEnginesBOMm[0]?.installed}X {i}
                                        </li>
                                      )
                                    )}
                                  </ul>  
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-12 col-xl-6">
                              <div className="row" >
                                <div className="col-md-6" style={{width:"25%"}}>
                                  <h4 className="key">Target Speed:</h4>
                                </div>
                                <div className="col-md-9">
                                  <p className="value" style={{textAlign: 'left'}}>
                                    {item?.targetSpeedKt} kt
                                  </p>
                                </div>
                              </div>
                              <h4 className="key" style={{textAlign: 'left'}}>Tanks:</h4>
                              <ul className="tank-detail" style={{textAlign: 'left', paddingLeft: '0.75rem'}}>
                                {Object.entries(item.tankCapacityM3).map(
                                  (i, index) => (
                                    <li key={index}>
                                      <p className="value">
                                        {i[0]}:{" "}
                                        <span>{formatNumber(i[1])} m3</span>
                                      </p>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="route-action-container">
                            <div className="vessel-detail-container" style={{width: '64%', textAlign:'left'}}>
                              <div className="header route-title">
                                <h3>
                                  <span>Selected Route: </span>
                                  {selectedVesselItems[item.id] &&
                                  selectedVesselItems[item.id].vesselId ===
                                    item.id &&
                                  selectedVesselItems[item.id].routeName !==
                                    undefined
                                    ? selectedVesselItems[item.id].routeName
                                    : item?.selectedRoute}
                                </h3>
                                {canAccessRoute("VesselRouteAssignAndRevenue") &&
                                <div>
                                  <CustomTooltip
                                    tooltipText={'Assign Route'} 
                                    placement="top"
                                  >
                                    <button className="btn btn-sm assign-route"
                                    onClick={() => handleAssignRoute(item)}>
                                      <img src={AssignRouteNew} alt="" />
                                    </button>
                                  </CustomTooltip>
                                </div>
                                }
                              </div>
                              <div className="row" style={{ textAlign: 'left' }}>
                              <div className="col-12">
                                <div className="row custom-row d-flex justify-content-between flex-grow-1 mb-2">
                                  <div>
                                    <h4 className="key">Round Trips:</h4>
                                  </div>
                                  <div>
                                    <p className="value text-end">
                                      {selectedVesselItems[item.id] &&
                                      selectedVesselItems[item.id].vesselId === item.id &&
                                      selectedVesselItems[item.id]?.yearlyRt !== undefined
                                        ? selectedVesselItems[item.id]?.yearlyRt.toFixed(2)
                                        : item.myRouteKPI && item.myRouteKPI.yearlyRt !== undefined
                                        ? item.myRouteKPI.yearlyRt.toFixed(2)
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>
                                <div className="row custom-row d-flex justify-content-between flex-grow-1 mb-2">
                                  <div>
                                    <h4 className="key">Total Distance:</h4>
                                  </div>
                                  <div>
                                    <p className="value">
                                      {formatNumber(
                                        selectedVesselItems[item.id] &&
                                        selectedVesselItems[item.id].vesselId === item.id &&
                                        selectedVesselItems[item.id].nmTotal !== undefined
                                          ? selectedVesselItems[item.id]?.nmTotal
                                          : item.myRouteKPI && item.myRouteKPI.nmTotal !== undefined
                                          ? item.myRouteKPI.nmTotal
                                          : "N/A"
                                      )}{" "}
                                      nm
                                    </p>
                                  </div>
                                </div>
                                <div className="row custom-row d-flex justify-content-between flex-grow-1 mb-2">
                                  <div>
                                    <h4 className="key">Total Days At Port:</h4>
                                  </div>
                                  <div>
                                    <p className="value">
                                      {formatNumber(
                                        selectedVesselItems[item.id] &&
                                        selectedVesselItems[item.id].vesselId === item.id &&
                                        selectedVesselItems[item.id].portDaysTotal !== undefined
                                          ? selectedVesselItems[item.id].portDaysTotal
                                          : item.myRouteKPI.portDaysTotal
                                      )}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className="row custom-row d-flex justify-content-between flex-grow-1 mb-2">
                                  <div>
                                    <h4 className="key">% for EU:</h4>
                                  </div>
                                  <div>
                                    <p className="value">
                                      {(selectedVesselItems[item.id] &&
                                      selectedVesselItems[item.id].vesselId === item.id &&
                                      selectedVesselItems[item.id].eu_ptg !== undefined
                                        ? selectedVesselItems[item.id].eu_ptg
                                        : item?.myRouteKPI?.eu_ptg
                                      ).toFixed(2)}%
                                    </p>
                                  </div>
                                </div>
                                <div className="row custom-row d-flex justify-content-between flex-grow-1 mb-2">
                                  <div>
                                    <h4 className="key">Total Days:</h4>
                                  </div>
                                  <div>
                                    <p className="value">
                                      {(selectedVesselItems[item.id] &&
                                      selectedVesselItems[item.id].vesselId === item.id &&
                                      selectedVesselItems[item.id].daysTotal !== undefined
                                        ? selectedVesselItems[item.id].daysTotal
                                        : item?.myRouteKPI?.daysTotal
                                      ).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                                <div className="row custom-row d-flex justify-content-between flex-grow-1">
                                  <div>
                                    <h4 className="key">Revenue/rt:</h4>
                                  </div>
                                  <div>
                                    <p className="value">
                                      $
                                      {canAccessRoute("EditRoutes") && canAccessRoute("EditVessels")
                                        ? revenueValues[item.id] &&
                                          revenueValues[item.id]?.routeId === item?.myRevenueEntry?.routeId
                                          ? formatNumber(revenueValues[item.id].revenueValue)
                                          : formatNumber(item?.myRevenueEntry?.revenueRTUSD)
                                        : selectedVesselItems[item.id] &&
                                          selectedVesselItems[item.id].vesselId === item.id &&
                                          selectedVesselItems[item.id].revenue !== undefined
                                        ? formatNumber(selectedVesselItems[item.id].revenue)
                                        : formatNumber(item?.myRevenueEntry?.revenueRTUSD)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                            <div className="vessel-detail-container" style={{width: '45%'}}>
                              <div className="header route-title">
                                <h3><span>Select Vessel: </span></h3>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    role="switch"
                                    id={`flexSwitchCheckChecked_${item.id}`}
                                    checked={!!selectedVesselItems[item.id]}
                                    onChange={(e) =>
                                      handleSelectedVessel(item, e)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12 col-xl-12 col-lg-12">
                                  <div className="row">
                                    <div className="col-md-12" style={{textAlign: 'left'}}>
                                      <h4 className="key">Target CII score: </h4>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="cii-score-container">
                                        <div className="custom-radio mb-3">
                                          {CIISCORE.map((score) => (
                                            <div
                                              className="radio-item"
                                              key={score.id}
                                            >
                                              <input
                                                type="radio"
                                                value={score.ciiscore}
                                                name={`cii_${item.id}`}
                                                id={`${score.ciiscore}_${item.id}`}
                                                checked={
                                                  selectedVesselItems[item.id]
                                                    ?.ciiScore === score.ciiscore
                                                }
                                                onChange={(e) =>
                                                  handleSelectCiiScore(item, e)
                                                }
                                              />
                                              <label
                                                className="label-icon"
                                                htmlFor={`${score.ciiscore}_${item.id}`}
                                                style={{
                                                  background:
                                                    selectedVesselItems[item.id]
                                                      ?.ciiScore ===
                                                      score.ciiscore ||
                                                    (!selectedVesselItems[item.id]
                                                      ?.ciiScore &&
                                                      score.ciiscore === "C")
                                                      ? score.color
                                                      : "transparent",
                                                  borderColor: score.color,
                                                  color:
                                                    selectedVesselItems[item.id]
                                                      ?.ciiScore ===
                                                      score.ciiscore ||
                                                    (!selectedVesselItems[item.id]
                                                      ?.ciiScore &&
                                                      score.ciiscore === "C")
                                                      ? "#FFFFFF"
                                                      : score.color,
                                                }}
                                              >
                                                {score.ciiscore}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  { isPartialYear && (
                                    <div className="row align-items-center">
                                        <div className="col-9 text-start">
                                          <h4 className="key-header">
                                            <span>Year-to-Date Emissions</span>
                                          </h4>
                                        </div>
                                      <div className="value col-3 text-end">
                                        <CustomTooltip
                                          tooltipText={"Edit Emissions"}
                                          placement="top"
                                        >
                                          <img
                                            className="cursor-pointer mb-1"
                                            src={EditBtnIcon}
                                            alt=""
                                            onClick={(e) => showRevenue(e, item)}
                                          />
                                        </CustomTooltip>
                                      </div>
                                    </div>
                                  )}
                                  {showRevenueModal === item.id && (
                                      <div
                                        onClick={(e) => e.stopPropagation()}
                                        className="position-absolute edit-revenue-container"
                                        ref={modalRef}
                                      >
                                      <div className="edit-revenue w-100 align-items-center justify-content-around flex-column" ref={modalRef}>
                                        <div className="d-flex gap-3 w-100 justify-content-between">
                                          <div className="w-50">
                                            <div>
                                              <p className="input-label revenue-modal-title mb-2">CII to Date</p>
                                              <div className="gap-2">
                                                <div className="text-start mb-2">
                                                  <p className="input-label">Global CO2</p>
                                                  <div className="mail-input pos-relative parameter common-input-v2">
                                                    <span>mtCO2eq</span>
                                                    <input
                                                      type="text"
                                                      placeholder="Value"
                                                      defaultValue={emissionInventory?.[item.id]?.global_emissionsToDate_mtCO2eq.toFixed(1) || null}
                                                      onChange={(e) => handlGlobalEmissionToDateChange(e)}
                                                      onKeyDown={(event) => { handleOnKeyDown(event) }}
                                                      onWheel={(e) => handleOnWheel(e)}
                                                      className="mb-1 mt-1"
                                                    />
                                                  </div>
                                                  {globalEmissionToDate && globalEmissionError && <p className="error mb-0 h-auto">{globalEmissionError }</p>}
                                                </div>
                                                <div className="text-start mb-2">
                                                  <p className="input-label">Global Work</p>
                                                  <div className="mail-input pos-relative parameter common-input-v2">
                                                    <span>mt-nm</span>
                                                    <input
                                                      type="text"
                                                      placeholder="Value"
                                                      defaultValue={emissionInventory?.[item.id]?.global_workToDate_mtNm.toFixed(1) || null}
                                                      onChange={(e) => handlGlobalWorkToDateChange(e)}
                                                      className="border-1 mt-1"
                                                      onKeyDown={(event) => { handleOnKeyDown(event) }}
                                                      onWheel={(e) => handleOnWheel(e)}
                                                    />
                                                  </div>
                                                  {globalWorkToDate && globalWorkError && <p className="error mb-0 h-auto">{globalWorkError }</p>}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="revenue-border w-50">
                                            <div className="ms-4">
                                              <p className="input-label revenue-modal-title mb-2">EU-FM to Date</p>
                                              <div className="gap-2">
                                                <div className="text-start mb-2">
                                                  <p className="input-label">EU GHG</p>
                                                  <div className="mail-input pos-relative parameter common-input-v2">
                                                    <span>mtCO2eq</span>
                                                    <input
                                                      type="text"
                                                      placeholder="Value"
                                                      defaultValue={emissionInventory?.[item.id]?.eu_emissionsToDate_mtCO2eq.toFixed(1) || null}
                                                      onChange={(e)=>handlEmissionToDateChange(e)}
                                                      className="border-1 mt-1"
                                                      onKeyDown={(event) => { handleOnKeyDown(event) }}
                                                      onWheel={(e) => handleOnWheel(e)}
                                                    />
                                                  </div>
                                                  {emissionToDate && emissionError && <p className="error mb-0 h-auto">{emissionError }</p>}
                                                </div>
                                                <div className="text-start mb-2">
                                                  <p className="input-label">EU Energy</p>
                                                  <div className="mail-input pos-relative parameter common-input-v2">
                                                    <span>GJ</span>
                                                    <input
                                                      type="text"
                                                      placeholder="Value"
                                                      defaultValue={emissionInventory?.[item.id]?.eu_energyToDate_GJ.toFixed(1) || null}
                                                      onChange={(e) => handlEnergyToDateChange(e)}
                                                      className="border-1 mt-1"
                                                      onKeyDown={(event) => { handleOnKeyDown(event) }}
                                                      onWheel={(e) => handleOnWheel(e)}
                                                    />
                                                  </div>
                                                  {energyToDate && energyError && <p className="error mb-0 h-auto">{ energyError}</p>}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                          <div className="edit-revenue-action gap-2">
                                            <Button
                                              className="btn btn-secondary"
                                              type="button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setShowRevenueModal(null);
                                              }}
                                            >
                                              Cancel
                                            </Button>
                                            <button
                                              className="btn btn-primary"
                                              type="submit"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleChangeEmissions(e, item);
                                              }}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                  )}
                                  {isPartialYear && (
                                    <div className="row align-items-center">
                                      <div className="col-9 text-start">
                                        <h4 className="key custom-key">
                                          {emissionInventory ? (
                                            emissionInventory[item.id] ? (
                                              `Fetched from compliance system on ${
                                                emissionInventory[item.id]?.inventoryDate
                                                  ? new Date(emissionInventory[item.id].inventoryDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                                                  : ''
                                              }`
                                            ) : (
                                                <div className="loader-display">
                                                  Could not fetch latest data
                                                </div>
                                            )
                                          ) : (
                                            'Fetching data...'
                                          )}
                                        </h4>
                                      </div>
                                      <div className="col-3 text-end">
                                         <p className="value custom-value" style={{ textAlign: 'right' }}>
                                            {emissionInventory ? (
                                              emissionInventory[item.id] ? (
                                                <img src={SuccessCompliance} alt="Success Compliance" />
                                              ) : (
                                                <img src={FailCompliance} alt="Fail Compliance" />
                                              )
                                          ) : (
                                              <div className="loader-display">
                                                <Loading  width={30} height={30} />
                                              </div>
                                          )}
                                          </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      </div>
                    </div>  
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <Loading loading={vesselDto.length === 0} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main> 
      </div>

      <AssignRouteModal
        show={showAssignRoute}
        handleClose={() => {
          getAllVesselDto();
          setShowAssignRoute(false);
        }}
        routesPattern={routeData}
        selectSpeed={selectSpeed}
        selectedRoute={selectedRoute}
        showError={showError}
        changeSpeed={changeSpeed}
        handleRoute={handleRoute}
        handleRouteSave={handleRouteSave}
        speedMax={speedMax}
        speedMin={speedMin}
        setMap={setMap}
        defaultProps={defaultProps}
        loadingMap={loadingMap}
        setLoadingMap={setLoadingMap}
        loadingRouteData={loadingRouteData}
        selectedVessel={selectedVessel}
        loadingRouteKpi={loadingRouteKpi}
        showLoading={showLoading}
        revenue={revenue}
        getRevenueByOwner={() => getRevenueByOwner()}
      />

      <EditVesselModal
        show={showEditVesselModal}
        handleClose={() => {
          setIsEdit(false);
          setShowEditVesselModal(false);
        }}
        auxEnginData={auxEnginData}
        fuelData={fuelData}
        listAux={listAux}
        listMe={listMe}
        listFuelType={listFuelType}
        listVesselType={listVesselType}
        data={item}
        isEdit={isEdit}
      />

      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.message === "Network Error"
                ? "The system is temporarily out of service. Please contact us if this error persists."
                : error.response.data.message
              : "The system is temporarily out of service. Please contact us if this error persists."
            : "No error"
        }
      />
    </>
  );
};

export default Home;
