import React, { useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthContext";
import AppNavigation from "./AppNavigation";
import AuthNavigation from "./AuthNavigation";
import useLogout from "../hooks/ useLogout";

const MainNavigator = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const handleLogOut = useLogout();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const session = queryParams.get('session');
    if (session === 'false') {
      setIsLoggedIn(false)
      handleLogOut()
    }

    const userData = localStorage.getItem("accessToken");
    if (userData) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [setIsLoggedIn]);

  return <>{isLoggedIn ? <AppNavigation /> : <AuthNavigation />}</>;
};

export default MainNavigator;
