import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "../pages/Home";
import Faq from "../pages/Faq";
import FleetWideResult from "../pages/FleetWideResult";
import LegislativeScenario from "../pages/LegislativeScenario";
import FuelPriceForecast from "../pages/FuelPriceForecast";

export default function AppNavigation() {
  const permissions = useSelector((state) => state.permissions.permissions);

  const canAccessRoute = (requiredPermission) => {
    const today = new Date();
    return permissions.some((permission) => {
      const permissionDate = new Date(permission.expirationDate);
      return (
        permission.licensePermission.permissionName === requiredPermission &&
        permissionDate >= today
      );
    });
  };

  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/faqs" element={<Faq />}></Route>
      <Route
        exact
        path="/fleetwideresult"
        element={<FleetWideResult />}
      ></Route>
      <Route
        exact
        path="/legislativescenario"
        element={
          permissions.length > 0 &&
          canAccessRoute("EditLegislativeScenarios") ? (
            <LegislativeScenario />
          ) : (
            <Navigate to="/" replace={true} />
          )
        }
      ></Route>
      <Route
        exact
        path="/fuelpriceforecast"
        element={          
            <FuelPriceForecast />
        }
      ></Route>
    </Routes>
  );
}
