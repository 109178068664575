export const barPatterns = {
  id: "barPatterns",
  beforeDatasetsDraw(chart, args, plugins) {
    const {
      ctx,
      data,
      chartArea: { left, width },
      scales: { y },
    } = chart;
    ctx.save();
    const responsiveThreshold = 280;
    if (width > responsiveThreshold) {
      ctx.fillStyle = "#FFFFFF";
      data.labels.forEach((bar, index) => {
        ctx.fillRect(left, y.getPixelForValue(index) - 5, width, 10);
      });
    }

    ctx.restore();
  },
};
