import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "../Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CiiZFactor = ({ chartData }) => {
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (chartData.length > 0) {
      const label = chartData.map((i) => i.value0);
      const dataset = [
        {
          label: "CII Z-Factor by year  ",
          data: chartData.map((i) => i.value1),
          borderColor: "#71A4F6",
          backgroundColor: "#71A4F6",
          tension: 0.2,
          pointRadius: 1,
          borderWidth: 3,
        },
      ];

      setDataset(dataset);
      setLabel(label);
    }
  }, [chartData]);

  const dataCii = {
    labels: label,
    datasets: dataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = JSON.parse(context.formattedValue);
            const multipliedValue = value * 100;
            const formattedPercentage = multipliedValue.toFixed(1);
            return `  ${formattedPercentage}%`;
          },
        },
      },
      legend: {
        display: true,
        rtl: true,
        position: "bottom",
        align: "center",
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: "circle",
          textAlign: "center",
          padding: 20,
          font: {
            lineHeight: 2,
            family: "Cabin, sans-serif",
            size:15
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          font: "10px",
          lineHeight: "13px",
          family: "Cabin, sans-serif",
          callback: function (value, index, ticks) {
            return (value.toFixed(2) * 100).toLocaleString() + "%";
          },
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="h-100">
      {chartData.length > 0 ? (
        <Line options={options} data={dataCii} />
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Loading loading={chartData.length === 0} height={30} />
        </div>
      )}
    </div>
  );
};

export default CiiZFactor;
