import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refineData: [],
};

const refineDataSlice = createSlice({
  name: "refineData",
  initialState,
  reducers: {
    addRefineData(state, action) {
      const existingIndex = state.refineData.findIndex(
        (item) =>
          item.ft === action.payload.ft &&
          item.priceYear === action.payload.priceYear
      );

      if (existingIndex !== -1) {
        state.refineData[existingIndex].pricePerMt = action.payload.pricePerMt;
      } else {
        state.refineData.push(action.payload);

      }
    },
    clearRefineData(state) {
      state.refineData = [];
    },
  },
});

export const { addRefineData, clearRefineData } = refineDataSlice.actions;
export default refineDataSlice.reducer;
