import React, { useEffect, useState } from "react";

import Navbar from "../components/Navbar";
import { allFAQ } from "../api/data";

import FaqImg from "../assets/faq.svg";
import ArrowDown from "../assets/arrowDown.svg";
import ArrowUp from "../assets/arrowUp.svg";
import Loading from "../components/Loading";

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  const [loadingFaqData, setLoadingFaqData] = useState(false);
  const [expandedId, setExpandedId] = useState();

  const getAllFaqs = () => {
    setLoadingFaqData(true);
    allFAQ()
      .then((result) => {
        setFaqData(result.data);
        setExpandedId(result.data[0].id);
      })
      .catch((err) => console.log("err faq data ", err))
      .finally(() => setLoadingFaqData(false));
  };

  useEffect(() => {
    getAllFaqs();
  }, []);

  const handleShow = (faq) => {
    setExpandedId(faq.id === expandedId ? null : faq.id);
  };

  return (
    <>
      <div className="login-container h-100 pb-5">
        <div className="pb-5">
          <Navbar show={true} showversion={true} />
          <div className="faq-container">
            <h2>
              <img src={FaqImg} alt="" />
              Frequently Asked Questions (FAQs)
            </h2>
            {loadingFaqData ? (
              <div className="d-flex align-items-center justify-content-center h-100 mt-5 pt-5">
                <Loading loading={loadingFaqData} />
              </div>
            ) : (
              <>
                {faqData.map((faq, index) => (
                  <div key={index} className="question-container">
                    <ul>
                      <li>
                        <div
                          className="d-flex align-items-center justify-content-between cursor-pointer"
                          onClick={() => handleShow(faq)}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: faq.fr_question,
                            }}
                            className="question"
                          ></div>
                          <img
                            src={expandedId === faq.id ? ArrowUp : ArrowDown}
                            alt=""
                          />
                        </div>
                        {expandedId === faq.id && (
                          <div
                            className="answer"
                            dangerouslySetInnerHTML={{ __html: faq.fr_answer }}
                          ></div>
                        )}
                      </li>
                    </ul>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
