import React, { useContext, useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import "../css/main.css";
import "react-toastify/dist/ReactToastify.css";

import { NavLink, useNavigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";
import useSession from "../hooks/useSession";

import LoginLogo from "../assets/logo2.svg";
import axios from "axios";
import Navbar from "../components/Navbar";
import { reactAppConfig } from "../config/react-app-config";

import ErrorOverlaymodal from "../components/ErrorOverlayModal";

const schemaPass = yup
  .object({
    username: yup.string().email().required("Please Enter Email"),
    password: yup.string().required("Please Enter Password"),
  })
  .required();

const LoginScreen = () => {
  const navigate = useNavigate();

  const {
    register: registerPass,
    handleSubmit: handlePass,
    clearErrors,
    reset,
    formState: { errors: errorPass },
  } = useForm({
    resolver: yupResolver(schemaPass),
  });

  const { setIsLoggedIn } = useContext(AuthContext);
  const { login } = useSession();

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<MdOutlineVisibilityOff />);
  const [showLoading, setShowLoading] = useState(false);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const handleLogin = async (userDetail) => {
    setShowLoading(true);
    let data = new FormData();
    data.append("password", userDetail.password);
    data.append("username", userDetail.username);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${reactAppConfig.baseUrl}/api/auth/login`,
      headers: {
        accept: "application/json",
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("identifier", response.data.identifier);
      localStorage.setItem("userEmail", userDetail.username);
      setIsLoggedIn(true);
      navigate("/");
      login(response.data.accessToken);
      reset({ username: "", password: "" });
    } catch (error) {
      if (error.message === "Network Error"  && error.code === "ERR_NETWORK") {
        setError(error);
        setOpen(true);
      } else {
        toast.error("Please enter valid username and password", {
          position: "bottom-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "Login_User",
        });
        reset({ username: "", password: "" });
      }
    } finally {
      setShowLoading(false);
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(<MdOutlineVisibility />);
      setType("text");
    } else {
      setIcon(<MdOutlineVisibilityOff />);
      setType("password");
    }
  };

  return (
    <>
      <div className="login-container">
      <Navbar/>
        <div className="d-flex" >
          <div className="" style={{ padding: '6vh 0vw 0vh 4vw', width: '60vw' }}>
            <div className="login_left_container pos-relative d-flex gap-3vh flex-column">
              <div className="headings">
                <h3>Introducing advanced fleet-wide emissions management</h3>
                <p>
                  Our software simplifies maritime emissions compliance, guiding
                  users through complex regulations like the EU Fuel Maritime, IMO
                  CII, and EU ETS. It's a fleet-wide optimization tool that
                  advises on eco-friendly fuel choices, like bio- or synthetic
                  fuels, and smartly manages fuel maritime credit trading between
                  vessels and over time. Streamline compliance and boost
                  sustainability with our innovative solution.
                </p>
              </div>
              <div className="login_form_container">
              <div className="login-detail d-flex flex-column gap-5vh">
                <div className="text-center">
                  <span className="big-text-vw"> Pool.FM</span>
                </div>
                <form onSubmit={handlePass(handleLogin)}>
                  <div className="mail-input pos-relative fts-1vw">
                    <input
                      className={`${errorPass.username ? "error-input" : ""}`}
                      name="username"
                      placeholder="Enter Your Email"
                      {...registerPass("username")}
                      onBlur={(e) => {
                        const { name } = e.target;
                        if (name === "username" && errorPass.username) {
                          clearErrors("username");
                        }
                      }}
                    />
                  </div>
                  <div className="pass-input pos-relative mt-2vh fts-1vw">
                    <div className="visibility-icon" onClick={handleToggle}>
                      {icon}
                    </div>
                    <input
                      className={`${errorPass.password ? "error-input" : ""}`}
                      type={type}
                      name="password"
                      placeholder="Enter Your Password"
                      {...registerPass("password")}
                      onBlur={(e) => {
                        const { name } = e.target;
                        if (name === "password" && errorPass.password) {
                          clearErrors("password");
                        }
                      }}
                    />
                  </div>
                  
                  <div className="text-center mt-2vh">
                    <button className="btn btn-primary w-100 filter-none custom-btn fts-1vw" type="submit">
                      LOG IN
                      {showLoading && (
                        <div className="spinner-border" role="status"></div>
                      )}
                    </button>
                  </div>
                  <p className="forgot-password text-center mt-2vh mb-2vh fts-1vw">
                    <NavLink to="/forgotpassword">Forgot Password?</NavLink>
                  </p>
                  <p className="sign-up mt-0 mb-2vh">
                    Don’t have account?
                    <a href="mailto:contact@z-joule.com">
                      {" "}
                      Contact Us{" "}
                    </a>
                  </p>
                </form>
              </div>
            </div>
            </div>
          </div>

          <div className="login-bg">
            <div className="">
              <div className="">
                <img
                  src={
                    "https://storage.googleapis.com/vessel-images/pool-new-login-splash.png"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage="The system is temporarily out of service. Please contact us if this error persists."
        isLoginScreen={true}
     />
    </>
  );
};

export default LoginScreen;
