import { useContext, useEffect } from "react";
import axiosIns from "./axios";
import AuthContext from "../contexts/AuthContext";
import { toast } from "react-toastify";
import useLogout from "../hooks/ useLogout";

const AxiosErrorHandler = ({ children }) => {
  const { setIsLoggedIn } = useContext(AuthContext);

  const logout = useLogout();

  useEffect(() => {
    const responseInterceptor = axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          console.log("here");
          try {
            logout();
            toast.error(
              "Token expired. Please login with username and password",
              {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
                toastId: "Auth-error",
              }
            );
            localStorage.clear();
          } catch (error) {
            console.log("interceptors.response", error);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosIns.interceptors.response.eject(responseInterceptor);
    };
  }, [setIsLoggedIn]);

  return children;
};

export default AxiosErrorHandler;
