// TODO: Why is this code still in this repository -- should be in Scenarios repo !!
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import NavbarComponent from "../components/Navbar";
import SelectBox from "../components/SelectBox";
import {
  allLegislativeScenario,
  getOneScenario,
  getOwner,
  updateScenario,
} from "../api/data";
import { setLegislativeScenarioData } from "../redux/reducers/selectedLegislativeScenario";

import ImoTabContent from "../components/ImoTabContent";
import EuTabContent from "../components/EuTabContent";
import Loading from "../components/Loading";
import BackArrow from "../assets/arrow-left.svg";
import Years from "../utils/Years.json";
import ErrorOverlaymodal from "../components/ErrorOverlayModal";
 
 const LegislativeScenario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const legislativeScenario = useSelector(
    (state) => state.selectedLegislativeScenario.legislativeScenario
  );

  const [selectedScenarioData, setSelectedScenarioData] = useState(null);
  const [scenarioValue, setScenarioValue] = useState("");
  const [zFactorValue, setZFactorValue] = useState(0.04);
  const [imoLevyAmount, setImoLevyAmount] = useState("");
  const [imoPenalty, setImoPenalty] = useState("");
  const [startYear, setStartYear] = useState({ id: null, item: "Select year" });
  const [showYearError, setShowYearError] = useState(false);
  const [years, setYears] = useState(Years);

  const [euPenalty, setEuPenalty] = useState("");
  const [euBaseYear, setEuBaseYear] = useState(null);
  const [euCostEur, setEuCostEur] = useState("");
  const [euCostCagr, setEuCostCagr] = useState(null);
  const [baseYear, setBaseYear] = useState({ id: null, item: "Select year" });
  const [showBaseYearError, setShowBaseYearError] = useState(false);

  const [legislativeScenarios, setLegislativeScenarios] = useState([]);
  const [showLegislativeError, setShowLegislativeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState(null);

  const [error, setError] = useState("");
  const [errorPenalty, setErrorPenalty] = useState("");
  const [errorLevyAmount, setErrorLevyAmount] = useState("");
  const [euPenaltyError, setEuPenaltyError] = useState("");
  const [euCostError, setEuCostError] = useState("");
  const [showGrowthError, setShowGrowthError] = useState("");

  const [showSpin, setShowSpin] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const tabs = [
    {
      id: "imo",
      label: "IMO",
      content: (
        <ImoTabContent
          zFactorValue={zFactorValue}
          setZFactorValue={setZFactorValue}
          imoLevyAmount={imoLevyAmount}
          setImoLevyAmount={setImoLevyAmount}
          imoPenalty={imoPenalty}
          setImoPenalty={setImoPenalty}
          startYear={startYear}
          setStartYear={setStartYear}
          showYearError={showYearError}
          setShowYearError={setShowYearError}
          years={years}
          setError={setError}
          setErrorPenalty={setErrorPenalty}
          error={error}
          errorPenalty={errorPenalty}
          errorLevyAmount={errorLevyAmount}
          setErrorLevyAmount={setErrorLevyAmount}
        />
      ),
    },
    {
      id: "eu",
      label: "EU",
      content: (
        <EuTabContent
          euPenalty={euPenalty}
          setEuPenalty={setEuPenalty}
          euCostEur={euCostEur}
          setEuCostEur={setEuCostEur}
          euCostCagr={euCostCagr}
          setEuCostCagr={setEuCostCagr}
          baseYear={baseYear}
          setBaseYear={setBaseYear}
          showBaseYearError={showBaseYearError}
          setShowbaseYearError={setShowBaseYearError}
          years={years}
          euPenaltyError={euPenaltyError}
          setEuPenaltyError={setEuPenaltyError}
          euCostError={euCostError}
          setEuCostError={setEuCostError}
          setShowGrowthError={setShowGrowthError}
          showGrowthError={showGrowthError}
        />
      ),
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const showToast = (message, toastId) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      toastId: toastId,
    });
  };

  const handleUpdateScenario = () => {
    const parsedValue = parseFloat(imoLevyAmount);
    const parsedImoPenalty = parseFloat(imoPenalty);
    const parsedEuPenalty = parseFloat(euPenalty);
    const parsedEuCostEur = parseFloat(euCostEur);
    if (legislativeScenario.id === null) {
      showToast("Please select legislative scenario", "legislative_scenario");
    } else if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 500 ||
      imoLevyAmount?.startsWith("-")
    ) {
      setErrorLevyAmount("Please enter a levy amount between 0 and 500");
      if (activeTab.id === "eu")
        showToast(
          "Please enter a levy amount between 0 and 500",
          "imoLevyAmount"
        );
    } else if (startYear.id === null) {
      setShowYearError(true);
      if (activeTab.id === "eu")
        showToast("Please select start year", "start_year");
    } else if (zFactorValue < 0 || zFactorValue > 0.1 || error.length > 0) {
      setError("Z-factor must be between 0% and 10%");
      if (activeTab.id === "eu")
        showToast("Z-factor must be between 0% and 10%", "z-factor");
    } else if (
      parsedImoPenalty < 0 ||
      parsedImoPenalty > 5000 ||
      !parsedImoPenalty ||
      imoPenalty.startsWith("-")
    ) {
      setErrorPenalty("Please enter penalty between 0 to 5000");
      if (activeTab.id === "eu")
        showToast("Please enter imo penalty between 0 to 5000", "imoPenalty");
    } else if (
      parsedEuCostEur < 0 ||
      parsedEuCostEur > 500 ||
      !parsedEuCostEur ||
      euCostEur.startsWith("-")
    ) {
      setEuCostError("Cost of allowance must be 0 to 500");
      if (activeTab.id === "imo")
        showToast("Cost of allowance must be 0 to 500", "euCostEur");
    } else if (baseYear.id === null) {
      setShowBaseYearError(true);
      if (activeTab.id === "imo")
        showToast("Please select base year", "base_year");
    } else if (
      parsedEuPenalty < 0 ||
      parsedEuPenalty > 5000 ||
      !parsedEuPenalty ||
      euPenalty.startsWith("-")
    ) {
      setEuPenaltyError("Please enter penalty between 0 to 5000");
      if (activeTab.id === "imo")
        showToast("Please enter eu penalty between 0 to 5000", "eu_penalty");
    } else if (
      euCostCagr < 0 ||
      euCostCagr > 0.1 ||
      !euCostCagr ||
      showGrowthError.length > 0
    ) {
      setShowGrowthError("Growth p/a must be 0 to 10%");
      if (activeTab.id === "imo")
        showToast("Growth p/a must be 0 to 10%", "growth_pa");
    } else {
      setShowSpin(true);
      const transformedData = {
        id: legislativeScenario.id,
        scenarioName: legislativeScenario.item,
        imo_cii_z_from_2027: zFactorValue,
        imo_ghg_levy_amout_usd_co2eq: parsedValue,
        imo_ghg_levy_start_year: startYear.item,
        imo_Intensity_penalty_eur_gj: parsedImoPenalty,
        eufmPenalty_eur_gj: parsedEuPenalty,
        euets_allowance_cost_eur_co2eq: euCostEur,
        euets_allowance_base_year: euBaseYear,
        euets_allowance_cagr: euCostCagr,
      };

      updateScenario(transformedData)
        .then((res) => {
          console.log(res.data);
          toast.success("Legislative scenario has been updated.", {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            toastId: "update_price_template",
          });
          setShowSpin(false);
        })
        .catch((err) => {
          setError(err);
          setOpen(true);
          console.log("error update scenario", err);
        });
    }
  };

  const getAllLegislativeScenario = () => {
    allLegislativeScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setLegislativeScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in price forcast scenario", err);
      });
  };

  const getScenarioById = (item) => {
    setLoading(true);
    getOneScenario(item)
      .then((res) => {
        setSelectedScenarioData(res.data);
        const foundYear = years.find(
          (obj) => obj.item === res.data?.imo_ghg_levy_start_year
        );
        setStartYear(foundYear);
        const findBaseYear = years.find(
          (obj) => obj.item === res.data?.euets_allowance_base_year
        );

        setBaseYear(findBaseYear);
        setImoLevyAmount(`${res.data?.imo_ghg_levy_amout_usd_co2eq}`);
        setImoPenalty(`${res.data?.imo_Intensity_penalty_eur_gj}`);
        setZFactorValue(res.data?.imo_cii_z_from_2027);
        setEuBaseYear(res.data?.euets_allowance_base_year);
        setEuCostEur(`${res.data?.euets_allowance_cost_eur_co2eq}`);
        setEuCostCagr(res.data?.euets_allowance_cagr);
        setEuPenalty(`${res.data?.eufmPenalty_eur_gj}`);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("get one scenario err", err);
      });
   };
   
  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  useEffect(() => {
    getAllLegislativeScenario();
    getOwnerPermission();
  }, []);

  useEffect(() => {
    if (legislativeScenario.id !== null) {
      getScenarioById(legislativeScenario.id);
    }
  }, [legislativeScenario]);

  const handleLegislativeScenarioChange = (item) => {
    if (item.id !== null) {
      getScenarioById(item.id);
    }
    dispatch(setLegislativeScenarioData(item));
  };

  return (
    <>
      <div className="login-container h-100 splash-screen pb-5 ">
        <NavbarComponent show={true} showfaq={true} showversion={true} />
        <main className="container-padding pt-4">
          <div className="legislativ-scenario-container ">
            <div className="title">
              <button
                className="btn btn-primary add back"
                onClick={() => navigate("/")}
              >
                <img src={BackArrow} alt="" />
              </button>
              <h1 className="mb-0 fs-28 text-dark">
                {ownerInfo?.name} Legislative Scenarios
              </h1>
              <div className="select-scenario d-flex ms-auto align-items-center">
                <p className="input-label mb-0 me-2">Scenario</p>

                <SelectBox
                  selectedItem={legislativeScenario}
                  listItems={[...legislativeScenarios, "+"]}
                  setSelectedItem={handleLegislativeScenarioChange}
                  disabledOptions={[legislativeScenario]}
                  showError={showLegislativeError}
                  setShowError={setShowLegislativeError}
                  showAddElement={true}
                  className="select-box-v2"
                />
              </div>
            </div>
          </div>
          <div className="legislative-main-container mt-4">
            <ul className="nav nav-tabs pb-4 ps-4" id="myTab" role="tablist">
              {tabs.map((tab) => (
                <li className="nav-item" role="presentation" key={tab.id}>
                  <button
                    className={`nav-link ${tab.id === "imo" ? "active" : ""}`}
                    id={`${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.id}-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`${tab.id}-tab-pane`}
                    aria-selected={tab.id === "imo"}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
            <div
              className="tab-content rounded-16 p-4 shadow"
              id="myTabContent"
            >
              {tabs.map((tab) => (
                <div
                  className={`tab-pane fade ${
                    tab.id === "imo" ? "show active" : ""
                  }`}
                  id={`${tab.id}-tab-pane`}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  tabIndex="0"
                  key={tab.id}
                >
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <Loading loading={loading} />
                    </div>
                  ) : (
                    tab.content
                  )}
                </div>
              ))}
              <div className="actions">
                <button
                  className="btn btn-secondary btn-lg fs-16"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary btn-lg fs-16"
                  onClick={handleUpdateScenario}
                >
                  Save
                  {showSpin && (
                    <div className="spinner-border" role="status"></div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.response.data.message
              : "Unknown error occurred"
            : "No error"
        }
      />
    </>
  );
};

export default LegislativeScenario;
