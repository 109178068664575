import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GoogleMap from "google-map-react";

import { changeRevenue } from "../api/data";
import { mapOptions } from "../utils/componentStyle";

import CloseIcon from "../assets/closeIcon.svg";
import EditBtnIcon from "../assets/editbtn.svg";
import SaveIcon from "../assets/check.svg";
import InfoIcon from "../assets/info.svg";

import { formatNumber } from "../utils/formatter";
import Loading from "./Loading";
import { setFreeRevenueValue } from "../redux/reducers/revenueValueSlice";
import { setSelectedVesselItems } from "../redux/reducers/selectedVesselItemsReducer";

import { reactAppConfig } from "../config/react-app-config";


const AssignRouteModal = ({
  show,
  handleClose,
  routesPattern,
  showError,
  selectedRoute,
  selectSpeed,
  handleRoute,
  handleRouteSave,
  speedMax,
  speedMin,
  changeSpeed,
  defaultProps,
  setMap,
  loadingMap,
  setLoadingMap,
  loadingRouteData,
  selectedVessel,
  loadingRouteKpi,
  showLoading,
  getRevenueByOwner,
  revenue,
}) => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.permissions);
  const selectedVesselItems = useSelector((state) => state.selectedVesselItems);

  const [expandedItem, setExpandedItem] = useState(null);
  const [showRevenueModal, setShowRevenueModal] = useState(null);
  const [revenueValue, setRevenueValue] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const revenueValues = useSelector((state) => state.revenueValue);

  const handleShowMore = (itemId, e) => {
    e.stopPropagation();
    setExpandedItem(itemId === expandedItem ? null : itemId);
  };

  const showRevenue = (e, item) => {
    e.stopPropagation();
    setShowRevenueModal(item.id);
  };

  const handleAddRevenue = (e, item) => {
    e.stopPropagation();
    if (canAccessRoute("EditRoutes") && canAccessRoute("EditVessels")) {
      console.log("update revenue");
      if (revenueValue) {
        changeRevenue(selectedVessel?.id, item.id, revenueValue)
          .then((result) => {
            getRevenueByOwner();
            setRevenueValue(null);
            setShowRevenueModal(null);
          })
          .catch((err) => console.log("Change revenue err", err));
      }

      const updatedSelectedItem = {
        vesselId: selectedVessel.id,
        routeId: item.id,
        ciiScore: !selectedVesselItems[selectedVessel.id]
          ? "C"
          : selectedVesselItems[selectedVessel.id].ciiScore,
        revenue: +revenueValue,
      };

      const updatedSelectedItems = {
        ...selectedVesselItems,
        [selectedVessel.id]: updatedSelectedItem,
      };

      dispatch(setSelectedVesselItems(updatedSelectedItems));
    } else {
      console.log("no revenue update");
      const updatedItem = {
        id: selectedVessel.id,
        revenueValue: revenueValue,
        routeId: item?.id,
      };
      const updatedItems = {
        ...revenueValues,
        [selectedVessel.id]: updatedItem,
      };
      dispatch(setFreeRevenueValue(updatedItems));

      setRevenueValue(null);
      setShowRevenueModal(null);

      const updatedSelectedItem = {
        vesselId: selectedVessel.id,
        routeId: item.id,
        ciiScore: !selectedVesselItems[selectedVessel.id]
          ? "C"
          : selectedVesselItems[selectedVessel.id].ciiScore,
        revenue: +revenueValue,
      };

      const updatedSelectedItems = {
        ...selectedVesselItems,
        [selectedVessel.id]: updatedSelectedItem,
      };

      dispatch(setSelectedVesselItems(updatedSelectedItems));
    }
  };

  const handleSpeedClick = () => {
    setShowInfo(true);
  };

  const canAccessRoute = (requiredPermission) => {
    const today = new Date();
    return permissions.some((permission) => {
      const permissionDate = new Date(permission.expirationDate);
      return (
        permission.licensePermission.permissionName === requiredPermission &&
        permissionDate >= today
      );
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Assign Route Controls</Modal.Title>
          <button className="btn btn-primary close" onClick={handleClose}>
            <img src={CloseIcon} alt="close" />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0">
          {showError.length > 0 && (
            <p className="text-error text-center">{showError}</p>
          )}
          <div className="row">
            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="map-conatiner">
                {loadingMap && (
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <Loading loading={loadingMap} />
                  </div>
                )}
                <div className="map-css">
                  <GoogleMap
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    bootstrapURLKeys={{
                      key: reactAppConfig.googleMapUrl,
                    }}
                    onGoogleApiLoaded={({ map, maps }) => {
                      setMap(map);
                      setLoadingMap(true);
                    }}
                    options={mapOptions}
                  ></GoogleMap>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="route-main-container">
                <div className="route-container">
                  <div className="scroll-container">
                    {loadingRouteData && (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <Loading loading={loadingRouteData} />
                      </div>
                    )}
                    {loadingRouteKpi && (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <Loading loading={loadingRouteKpi} />
                      </div>
                    )}
                    {!loadingRouteData && !loadingRouteKpi && (
                      <>
                        {routesPattern?.map((item) => {
                          const findRevenue = revenue.find(
                            (r) =>
                              r.vesselId === selectedVessel?.id &&
                              r.routeId === item.id
                          );

                          return (
                            <div
                              className={`route-detail ${
                                selectedRoute === item?.id ? "active-route" : ""
                              }`}
                              key={item.id}
                              onClick={() => handleRoute(item)}
                            >
                              <p className="title">{item?.routePatternName}</p>
                              <div className="row border-after pb-3">
                                <div className="col-xl-4 col-lg-12">
                                  <div className="route-detail-container">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">R/t:</h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? `${formatNumber(
                                                item?.routeKpiData?.yearlyRt.toFixed(
                                                  2
                                                )
                                              )}`
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">Total Days:</h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? formatNumber(
                                                item?.routeKpiData?.daysTotal.toFixed(
                                                  2
                                                )
                                              )
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">
                                          Days at port [d]:
                                        </h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? item?.routeKpiData?.portDaysTotal
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-lg-12">
                                  <div className="route-detail-container">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">SECA:</h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? `${formatNumber(
                                                item?.routeKpiData?.seca_ptg.toFixed(
                                                  2
                                                )
                                              )} %`
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">
                                          Distance in SECA:
                                        </h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? `${formatNumber(
                                                item?.routeKpiData?.seca_nm
                                              )} nm`
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">Total Distance:</h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? `${formatNumber(
                                                item?.routeKpiData?.nmTotal
                                              )} nm`
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-lg-12">
                                  <div className="route-detail-container border-0">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">NECA:</h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? `${formatNumber(
                                                item?.routeKpiData?.neca_ptg.toFixed(
                                                  2
                                                )
                                              )} %`
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h4 className="key">
                                          Distance in NECA:
                                        </h4>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="value">
                                          {item?.routeKpiData
                                            ? `${formatNumber(
                                                item?.routeKpiData?.neca_nm
                                              )} nm`
                                            : 0}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row pos-relative">
                                      <div className="col-md-6">
                                        <h4 className="key">
                                          Revenue:{" "}
                                          {canAccessRoute("VesselRouteAssignAndRevenue") && (
                                              <img
                                                className="cursor-pointer"
                                                src={EditBtnIcon}
                                                alt=""
                                                onClick={(e) =>
                                                  showRevenue(e, item)
                                                }
                                              />
                                            )}
                                        </h4>
                                      </div>
                                      <div className="col-md-6 ">
                                        <p className="value">
                                          {Object.keys(revenueValues).length > 0
                                            ? `$ ${
                                                revenueValues[
                                                  selectedVessel?.id
                                                ]?.routeId === item.id
                                                  ? formatNumber(
                                                      revenueValues[
                                                        selectedVessel?.id
                                                      ]?.revenueValue
                                                    )
                                                  : formatNumber(
                                                      findRevenue?.revenueRTUSD.toFixed(
                                                        0
                                                      )
                                                    )
                                              }`
                                            : `$ ${formatNumber(
                                                findRevenue?.revenueRTUSD.toFixed(
                                                  0
                                                )
                                              )}`}
                                        </p>
                                      </div>
                                    </div>
                                      {showRevenueModal === item.id && (
                                        <div
                                          onClick={(e) => e.stopPropagation()}
                                          className="position-absolute assign-revenue-container"
                                        >
                                          <div className="edit-revenue">
                                            <input
                                              type="number"
                                              placeholder="Enter revenue"
                                              onChange={(e) => {
                                                e.stopPropagation();
                                                setRevenueValue(e.target.value);
                                              }}
                                            />
                                            <div className="edit-revenue-action">
                                              <button
                                                className="save"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleAddRevenue(e, item);
                                                }}
                                                disabled={!revenueValue}
                                              >
                                                <img src={SaveIcon} alt="" />
                                              </button>
                                              <button
                                                className="close"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setShowRevenueModal(null);
                                                }}
                                              >
                                                <img
                                                  src={CloseIcon}
                                                  alt=""
                                                  className="close-icon"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="route-detail-container port">
                                <h4 className="key mt-2">Ports</h4>
                                <div className="row">
                                  {item.allMyPortsDTO
                                    .slice(0, 5)
                                    .map((port, index) => (
                                      <div className="col-md-4" key={index}>
                                        <p className="text-start value mt-2">
                                          <li>{port}</li>
                                        </p>
                                      </div>
                                    ))}

                                  {expandedItem === item.id &&
                                    item.allMyPortsDTO
                                      .slice(5)
                                      .map((port, index) => (
                                        <div className="col-md-4" key={index}>
                                          <p className="text-start value mt-2">
                                            <li>{port}</li>
                                          </p>
                                        </div>
                                      ))}
                                  {item.allMyPortsDTO.length > 5 && (
                                    <div className="col-md-4 text-start">
                                      <p
                                        className="show-more"
                                        onClick={(event) =>
                                          handleShowMore(item.id, event)
                                        }
                                      >
                                        {expandedItem === item.id
                                          ? "Show less"
                                          : `+ ${
                                              item.allMyPortsDTO.length - 5
                                            } more`}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div className="pos-relative">
                  <p className="speed-title">
                    Speed
                    <img src={InfoIcon} alt="" onClick={handleSpeedClick} />
                  </p>
                  {showInfo && (
                    <div className="speed-info-container">
                      <button
                        className="btn btn-primary close"
                        onClick={() => setShowInfo(false)}
                      >
                        <img src={CloseIcon} alt="close" />
                      </button>
                      <p className="mb-0">
                        Optimal vessel speed for this route will be determined
                        by the optimization algorithm. This control allows you
                        to explore the KPIs of this route at different speeds.{" "}
                      </p>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-10">
                      <div className="mt-3">
                        <div className="d-flex align-items-end justify-content-between speed-min-max">
                          <p> {speedMin.toFixed(1)} kt</p>
                          <p> {speedMax.toFixed(1)} kt</p>
                        </div>
                        <input type="range" 
                          className="form-range" 
                          id="speedRange"
                          aria-label="Temperature"
                          defaultValue={selectSpeed}
                          value={selectSpeed}
                          step={0.5}
                          min={speedMin}
                          max={speedMax}
                          onChange={changeSpeed}></input>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="speed-label-box h-100">
                        <p>{selectSpeed?.toFixed(1)} kt</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center mt-3">
          <Button
            className="btn btn-secondary btn-lg"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary btn-lg"
            type="submit"
            onClick={handleRouteSave}
          >
            Save
            {showLoading && (
              <div className="spinner-border" role="status"></div>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignRouteModal;
