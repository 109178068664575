import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Loading from "../Loading";
import { formatNumber } from "../../utils/formatter";
import InfoModal from "../InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { setSelctedPurchaseBy } from "../../redux/reducers/fuelPurchaseBy";
import { htmlLegendPlugin } from "../../utils/htmlLegends";
import SelectBox from "../SelectBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const FuelTab = ({ activeTab, showBtn = true }) => {
  const unitsArr = [
    {
      id: 1,
      item: "Expense [USD]",
    },
    { id: 2, item: "Quantity [mt]" },
    { id: 3, item: "Energy [GJ]" },
  ]; 
  const dispatch = useDispatch();
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [chartKey, setChartKey] = useState(0);

  const [qtPurchased, setQtPurchased] = useState([]);
  const [amountPurchased, setAmountPurchased] = useState({});
  const [show, setShow] = useState(false);
  const [ fuelUnits, setFuelUnits ] = useState(unitsArr);
  const [ selectedFuelUnit, setSelectedFuelUnit ] = useState(unitsArr[0]);

  

  const [randomString, setRandomString] = useState(
    Math.random().toString(36).substring(7)
  );

  const fuelResult = useSelector((state) => state.pdfData);

  useEffect(() => {
    if (activeTab === 5) {
      setChartKey((prevKey) => prevKey + 1);
    }
  }, [activeTab]);

  const purchaseBy = useSelector((state) => state.fuelPurchaseBy);
  
  const { fromYear } = useSelector((state) => state.selectedYearItem);

  

  useEffect(() => {
    if (Object.keys(fuelResult).length > 0) {
      const epsilon = fuelResult.epsilon;
      const resultsFuelPurchase =
        fuelResult?.myFuelPurchaseResults?.myResultsFuelPurchase;
      const labels = Object.keys(resultsFuelPurchase);
      const fuelNames = Array.from(
        new Set(
          Object.values(resultsFuelPurchase).flatMap((location) =>
            Object.values(location).flatMap(
              (pollutant) => `${pollutant.fuelTypeName}`
            )
          )
        )
      );
      fuelNames.map((fuelKey) => {
        const data = labels.map((label) => {
          const fuelData = resultsFuelPurchase[label];
          const matchingFuel = fuelData.find(
            (fuel) => `${fuel.fuelTypeName}` === fuelKey
          );

          return matchingFuel ? +matchingFuel.amountOfPurchase.toFixed(2) : 0;
        });

        setAmountPurchased(data);
        return data;
      });

      generateDataset(purchaseBy.unit, labels, fuelNames, resultsFuelPurchase);

      setLabel(labels);
    }
  }, [fuelResult, purchaseBy]);


  const generateDataset = (purchaseUnit, labels, fuelNames, resultsFuelPurchase) => {
    const createDataset = (fuelKey, valueKey) => {
      const data = labels.map((label) => {
        const fuelData = resultsFuelPurchase[label];
        const matchingFuel = fuelData.find(
          (fuel) => `${fuel.fuelTypeName}` === fuelKey
        );
        return matchingFuel ? +matchingFuel[valueKey].toFixed(2) : 0;
      });
  
      const amt = labels.map((label) => {
        const fuelData = resultsFuelPurchase[label];
        const matchingFuel = fuelData.find(
          (fuel) => `${fuel.fuelTypeName}` === fuelKey
        );
        return matchingFuel ? +matchingFuel.amountOfPurchase.toFixed(2) : 0;
      });
  
      const qty = labels.map((label) => {
        const fuelData = resultsFuelPurchase[label];
        const matchingFuel = fuelData.find(
          (fuel) => `${fuel.fuelTypeName}` === fuelKey
        );
        return matchingFuel ? +matchingFuel.quantityPurchased.toFixed(2) : 0;
      });
  
      const energy = labels.map((label) => {
        const fuelData = resultsFuelPurchase[label];
        const matchingFuel = fuelData.find(
          (fuel) => `${fuel.fuelTypeName}` === fuelKey
        );
        return matchingFuel ? +matchingFuel.energyPurchased_GJ.toFixed(2) : 0;
      });
  
      const matchingFuel = Object.values(resultsFuelPurchase)
        .flat()
        .find((fuel) => `${fuel.fuelTypeName}` === fuelKey);
  
      return {
        label: matchingFuel
          ? `${matchingFuel.fuelTypeName}`
          : fuelKey,
        backgroundColor: matchingFuel
          ? matchingFuel.renderStyle.myColor
          : "red",
        data: data,
        barThickness: 10,
        amountOfpurchase: amt,
        quantityOfPurchase: qty,
        energyPurchase: energy,
      };
    };
  
    let valueKey;
    if (purchaseUnit === "Expense [USD]") {
      valueKey = 'amountOfPurchase';
    } else if (purchaseUnit === "Quantity [mt]") {
      valueKey = 'quantityPurchased';
    } else if (purchaseUnit === "Energy [GJ]") {
      valueKey = 'energyPurchased_GJ';
    }
  
    if (valueKey) {
      const datasets = fuelNames.map(fuelKey => createDataset(fuelKey, valueKey));
      setDataset(datasets);
    }
  };

  
  
  const resultFuel = fuelResult.myFuelPurchaseResults?.myResultsFuelPurchase;

  const fuelColorData = [];

  for (const location in resultFuel) {
    resultFuel[location].forEach((fuelObj) => {
      const fuelNameOrigin = `${fuelObj.fuelFamilyName}-${fuelObj.fuelOriginName}`;
      const existingFuel = fuelColorData.find(
        (item) => item.fuelNameOrigin === fuelNameOrigin
      );

      if (existingFuel) {
        if (existingFuel.color !== fuelObj.renderStyle.myColor) {
          existingFuel.color = fuelObj.renderStyle.myColor;
        }
      } else {
        fuelColorData.push({
          fuelNameOrigin: fuelNameOrigin,
          color: fuelObj.renderStyle.myColor,
        });
      }
    });
  }

  const groupedFuels = {};

  fuelColorData.forEach((fuel) => {
    const prefix = fuel.fuelNameOrigin.split("-")[0];
    if (!groupedFuels[prefix]) {
      groupedFuels[prefix] = [];
    }
    groupedFuels[prefix].push(fuel);
  });

  const dataFuel = {
    labels: label,
    datasets: dataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: null },
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        border: {
          display: false,
        },
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value, index, ticks) {
            if (purchaseBy.unit === "Expense [USD]") {
              return "$" + formatNumber(value);
            } else {
              return formatNumber(value);
            }
          },
          color: "#212529",
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
        },
        title: {
          display: true,
          text: `Purchase by ${purchaseBy.unit}`,
          font: {
            size: 14,
            weight: "bold",
            family: "Cabin, sans-serif"
          },
          padding: {
            top: 30,
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          crossAlign: "far",
          color: "#000000",
          padding: 20,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"

          },
          autoSkip:false,
        },
        border: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 75
      }
    },
    plugins: {
      htmlLegend: {
        containerID: `legend-container${randomString}`,
      },
      legend: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositionerLeft",
        displayColors: false,
        callbacks: {
          label: function (context) {
            if (context !== undefined) {
              const dataIndex = context.dataIndex;
              const amountOfPurchase =
                context.dataset.amountOfpurchase[dataIndex];
              const quantityOfPurchase =
                context.dataset.quantityOfPurchase[dataIndex];
              const energyPurchase = context.dataset.energyPurchase[dataIndex];

              return [
                `Quantity Purchased: ${formatNumber(quantityOfPurchase)} mt `,
                `Amount of Purchase: ${formatNumber(amountOfPurchase)} USD`,
                `Energy Purchased: ${formatNumber(energyPurchase)} GJ `,
              ];
            }
            return context.label;
          },
          title: function (context) {
            if (context[0] !== undefined) {
              return context[0].label + "-" + context[0].dataset.label;
            }
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  const fuelContainerRef = useRef(null);
  const fuelChartRef = useRef(null);

  useEffect(() => {
    if (show) {
      fuelContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    fuelChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };


  const handleFuelUnitChange = (item) => {
    dispatch(setSelctedPurchaseBy({ id: item.id, unit: item.item }));
    setSelectedFuelUnit(item)
  };



  return (
    <div ref={fuelContainerRef}>
      <div className="tab-content-title fuel">
        <h2>
          Fuel Purchased Per Year <span className="fs-4"> {fromYear.item}</span>
        </h2>
        {showBtn && (
          <div className="d-flex mb-0 gap-2">
            <SelectBox
                selectedItem={selectedFuelUnit}
                listItems={fuelUnits}
                setSelectedItem={(item) =>
                  handleFuelUnitChange(item)
                }
                showSelect={false}
                showErrorPopup={false}
                selectBoxContainer="fuelUnit-container  w-10rem"
              />
            <button className="btn btn-primary" onClick={() => setShow(!show)}>
              Notes and Sources
            </button>
          </div>
        )}
      </div>
      <div className="fleet-chart-container d-flex flex-column gap-4">
        {Object.keys(fuelResult).length === 0 && (
          <div className="d-flex align-items-center justify-content-center flex-grow-1">
            <Loading loading={Object.keys(fuelResult).length === 0} />
          </div>
        )}
        {Object.keys(fuelResult).length > 0 && (
          <div className="flex-grow-1">
            <Bar
              data={dataFuel}
              options={options}
              key={chartKey}
              plugins={[htmlLegendPlugin]}
            />
          </div>
        )}
      </div>
      <div
        id={`legend-container${randomString}`}
        className="legend-data"
      ></div>
      <InfoModal
        ref={fuelChartRef}
        show={show}
        handleClose={handleClose}
        data={
          fuelResult?.myFuelPurchaseResults?.comments
            ? fuelResult?.myFuelPurchaseResults?.comments
            : "No data"
        }
      />
    </div>
  );
};

export default FuelTab;