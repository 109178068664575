import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import "../css/main.css";
import "react-toastify/dist/ReactToastify.css";

import { resetPassword } from "../api/data";

import MailIcon from "../assets/mailIcon.svg";

const schemaPass = yup
  .object({
    username: yup.string().email().required("Please Enter Email"),
  })
  .required();

const ForgotPassword = () => {
  const {
    register: registerPass,
    handleSubmit: handlePass,
    clearErrors,
    reset,
    formState: { errors: errorPass },
  } = useForm({
    resolver: yupResolver(schemaPass),
  });

  const [showLoading, setShowLoading] = useState(false);

  const handleLogin = (data) => {
    setShowLoading(true);
    data.stamp = new Date();
    resetPassword(data.username)
      .then((res) => {
        if (res.data) {
          toast.success(
            "A reset password link has been sent to your email address!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              toastId: "password-link-success",
            }
          );
          reset({ email: "" });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "user-not-found",
        });
        reset({ username: "" });
      })
      .finally(() => setShowLoading(false));
  };

  return (
    <>
      <div className="login-detail">
        <div className="text-center pb-4 forgot-password-container">
          <h2>Forgot Password?</h2>
          <p>To reset your password, enter your email address below</p>
        </div>
        <form onSubmit={handlePass(handleLogin)}>
          <div className="mail-input pos-relative">
            <img src={MailIcon} alt="" />
            <input
              className={`${errorPass.username ? "error-input" : ""}`}
              name="username"
              placeholder="Enter Your Email"
              {...registerPass("username")}
              onBlur={(e) => {
                const { name } = e.target;
                if (name === "username" && errorPass.username) {
                  clearErrors("username");
                }
              }}
            />
          </div>

          <div className="text-center mt-4">
            <button className="btn btn-primary btn-lg" type="submit">
              Reset Password
              {showLoading && (
                <div className="spinner-border" role="status"></div>
              )}
            </button>
          </div>
          <p className="sign-up">
            Back to<NavLink to="/"> Log in </NavLink>
          </p>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
