import axios from "axios";
import { reactAppConfig } from "../config/react-app-config";

const axiosIns = axios.create({
  baseURL: reactAppConfig.baseUrl,
});

let isRefreshing = false;
let refreshSubscribers = [];

const onReceivedRefreshedToken = (token) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export const getRefreshToken = (refreshToken) => {
  const data = new FormData();
  data.append("refreshToken", refreshToken);
  return axios.request({
    method: "POST",
    maxBodyLength: Infinity,
    url: `${reactAppConfig.baseUrl}/api/auth/regenerateAccessAndRefreshToken`,
    headers: {
      accept: "application/json",
    },
    data: data,
  });
};

axiosIns.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const isLoggedIn = !!token;
    const isRefreshTokenExpired = isTokenExpired(refreshToken);
    if (isLoggedIn && !isRefreshTokenExpired) {
      if (isTokenExpired(token)) {
        if (!isRefreshing) {
          isRefreshing = true;
          await getRefreshToken(refreshToken)
            .then((res) => {
              localStorage.setItem("accessToken", res.data.accessToken);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              localStorage.setItem("identifier", res.data.identifier);
              config.headers[
                "Authorization"
              ] = `Bearer ${res.data.accessToken}`;
              onReceivedRefreshedToken(res.data.accessToken);
            })
            .catch((error) => {
              console.error(
                "Error occurered while getting new access and referesh token:",
                error
              );
            });
          isRefreshing = false;
        } else {
          const retryOriginalRequest = new Promise((resolve) => {
            addRefreshSubscriber((token) => {
              config.headers["Authorization"] = `Bearer ${token}`;
              resolve(config);
            });
          });

          return retryOriginalRequest;
        }
      } else {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  try {
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = tokenData.exp * 1000;
    const currentTime = new Date().getTime();

    return currentTime >= expirationTime;
  } catch (error) {
    console.error("Error parsing access-token:", error);
    return true;
  }
}

export default axiosIns;
