import { Routes, Route } from "react-router-dom";

import LoginScreen from "../pages/LoginScreen";

import AuthOutlet from "../components/AuthOutlet";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

export default function AuthNavigation() {
  return (
    <Routes>
      <Route exact path="/" element={<LoginScreen />}></Route>
      <Route exact path="/" element={<AuthOutlet />}>
        <Route path="forgotpassword" element={<ForgotPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
      </Route>
      <Route path="*" element={<LoginScreen />}></Route>
    </Routes>
  );
}
