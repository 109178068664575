// TODO: Why is this file still in this project? Should be in the editors project.
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import dragdataPlugin from "../../utils/chartjs-plugin-dragdata";
import { Chart, registerables } from "chart.js";
import Loading from "../Loading";
import { useSelector, useDispatch } from "react-redux";
import {
  addRefineData,
  clearRefineData,
} from "../../redux/reducers/refineDataSlice";
import { refinePrices } from "../../api/data";
import { toast } from "react-toastify";
Chart.register(...registerables, dragdataPlugin);

const RefineChart = ({
  checkedPrice,
  chartDatas,
  fuelFamily,
  fuelOrigin,
  fuelTypeName,
  selectedBasin,
  loading,
  allFuelData,
  handlePrevious,
}) => {
  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const refineArrayData = useSelector(
    (state) => state.refineDataSlice.refineData
  );

  const [label, setLabel] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("Price by MMBtu (LHV)");

  const permissions = useSelector((state) => state.permissions.permissions);
  const canAccessRoute = (requiredPermission) => {
    const today = new Date();
    return permissions.some((permission) => {
      const permissionDate = new Date(permission.expirationDate);
      return (
        permission.licensePermission.permissionName === requiredPermission &&
        permissionDate >= today
      );
    });
  };

  useEffect(() => {
    if (checkedPrice === true) {
      setTitle("Price by MMBtu (LHV)");
    } else {
      setTitle("Price by Mt");
    }
  }, [checkedPrice]);

  useEffect(() => {
    if (!chartDatas || Object.keys(chartDatas).length === 0) {
    setLabel([]);
    setData([]);
    return;
    }

    if (chartDatas && Object.keys(chartDatas).length > 0) {
      let getSelectedFuelArray = []
      allFuelData.forEach((fuel) => {
        fuelOrigin.forEach((origin) => {
          if (fuel?.family?.fuelFamilyName === fuelFamily?.item && fuel?.origin?.id === origin.id) {
            getSelectedFuelArray.push(fuel)
          }
        });
      });

      const getUniqueYears = (fuels) => {
        const yearsSet = new Set();

        for (const fuelType in fuels) {
          const fuelData = fuels[fuelType];

          for (const entry of fuelData) {
            yearsSet.add(entry.year);
          }
        }
        return [...yearsSet];
      };
      const fuelValues = Object.values(chartDatas)[0];
      const uniqueYears = getUniqueYears(fuelValues);

      setLabel(uniqueYears);

      
      const fuelDatasetsNormalized = getSelectedFuelArray?.map(
        (fuel, index) => {
          const fuelTypeId = fuel.id;
          const getFuelFromArray = fuel

          const fuelValuesArray = fuelValues[fuelTypeId] ? fuelValues[fuelTypeId] : [];
          if (fuelValuesArray.length === 0) {
            return [];
          }
          else {
            fuelValuesArray?.sort((a, b) => a.year - b.year);

            return {
              label: fuel.fuelTypeName,
              data: fuelValuesArray.map((item) => checkedPrice ? +item.price_LHV.toFixed(1) : +item.price_mt.toFixed(1)),
              data_mt: fuelValuesArray.map((item) => +item.price_mt.toFixed(1)),
              data_lhv: fuelValuesArray.map((item) => +item.price_LHV.toFixed(1)),
              fuelId: fuelTypeId,
              borderDash:
                getFuelFromArray?.origin?.pattern === "dash"
                  ? [6, 6]
                  : getFuelFromArray?.origin?.pattern === "dot"
                    ? [2, 2]
                    : [0, 0],
              backgroundColor: getFuelFromArray?.family?.colorHex,
              borderColor: getFuelFromArray?.family?.colorHex,
              fill: false,
              lineTension: 0.4,
              radius: 1,
              borderWidth: 2,
              pointHitRadius: 25,
              pointRadius: 3,
            };
          }
        }
      );

      const fDSNormalizedFilteredArray = fuelDatasetsNormalized.filter(item => !(Array.isArray(item) && item.length === 0));
          
      setData(fDSNormalizedFilteredArray);

    }
  }, [chartDatas, fuelOrigin, fuelFamily, fuelTypeName, allFuelData]);

  const chartData = {
    labels: label,
    datasets: data,
  };

  const options = {
    maintainAspectRatio: false,
    hover: { mode: null },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          color: "#3c4450",
          font: 15,
          family: "Cabin, sans-serif",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return "$" + value;
          },
        },
        title: {
          display: true,
          text: `${
            checkedPrice ? "Fuel Price [USD/MMBtu]" : "Fuel Price [USD/Mt]"
          }`,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              const dataset = context.dataset;
              const dataIndex = context.dataIndex;

              const price_mt = dataset.data_mt[dataIndex];
              const price_lhv = dataset.data_lhv[dataIndex];
              return [
              `${dataset.label}`,
              `Mt Price: $${price_mt.toFixed(2)}/mt`,
              `LHV Price: $${price_lhv.toFixed(2)}/MMBtu`
              ];
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        title: {
          text: `${checkedPrice ? "Price by MMBtu (LHV)" : "Price by Mt"}`,
        },
        labels: {
          padding: 20,
          color: "#000",
          font: {
            size: 11,
            family: "Cabin, sans-serif",
            lineHeight: 14,
          },
          boxWidth: 70,
          boxHeight: 0,
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.lineWidth = 4;
              label.lineDashOffset = 5;
            });
            return originalLabels;
          },
        },
      },
      datalabels: {
        display: false,
      }
    },
  };

  if (canAccessRoute("EditPriceForecasts")) {
    options.plugins.dragData = {
      round: 1,
      showTooltip: true,
      onDragStart: (e, datasetIndex, index, value) => {
        if (!canAccessRoute("EditPriceForecasts")) {
          e.preventDefault();
          return;
        }
      },
      onDragEnd: (event, datasetIndex, dataIndex, value) => {
        if (!canAccessRoute("EditPriceForecasts")) {
          event.preventDefault();
          return;
        }
        const draggedDataset = data[datasetIndex];
        const priceYr = label[dataIndex];

        let addDragData = {
          myBasin: selectedBasin.id,
          scenario: priceScenario.id,
          ft: draggedDataset.fuelId,
          priceYear: priceYr,
          pricePerMt: value,
        };
        draggedDataset.data[dataIndex] = value;

        if (checkedPrice) {
          draggedDataset.data_lhv[dataIndex] = value;
        } else {
          draggedDataset.data_mt[dataIndex] = value;
        }

        // chart.update();

        dispatch(addRefineData(addDragData));
      },
    }
  }

  const addRefinePrice = (unit, refinePriceData) => {
    refinePrices(unit, refinePriceData)
      .then((res) => {
        toast.success("Your changes have been saved", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "updated_refine_data",
        });
      })
      .catch((err) => console.log("refine price err", err));
  };
  const handleRefineData = () => {
    const priceUnit = checkedPrice ? "mmbtu" : "mt";
    addRefinePrice(priceUnit, refineArrayData);

    dispatch(clearRefineData());
  };

  return (
    <>
      <div className="refine-price-chart-container">
        <p className="mb-0 d-none">{title}</p>
        <div className="chart-container border-1 rounded-3 mt-2">
          {label.length === 0 ||
            data.length === 0 ||
            (loading && (
              <div className="d-flex align-items-center justify-content-center h-100">
                <Loading
                  loading={label.length === 0 || data.length === 0 || loading}
                  height={30}
                />
              </div>
            ))}
          {label.length > 0 && data.length > 0 && !loading && (
            <Line options={options} data={chartData} />
          )}
        </div>
      </div>
      <div className="actions mt-3 d-flex justify-content-end w-100 gap-3">
        {(canAccessRoute("EditPriceForecasts") || canAccessRoute("BatchPriceEditor")) &&
          < button className="btn btn-primary btn-lg fs-16" onClick={() => handleRefineData()}>
          Confirm Refinement
        </button>}
      </div>
    </>
  );
};

export default RefineChart;
