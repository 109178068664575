import React, { forwardRef } from "react";
import CiiChart from "./charts/CiiChart";
import EtsTab from "./charts/EtsTab";
import IMOTab from "./charts/IMOTab";
import EuFmTab from "./charts/EuFmTab";
import EmissionTab from "./charts/EmissionTab";
import FuelTab from "./charts/FuelTab";
import RecommendationTab from "./charts/RecommendationTab";

const Pdf = forwardRef(
  (
    {
      componentRef1,
      componentRef2,
      componentRef3,
      componentRef4,
      componentRef5,
      componentRef6,
      componentRef7,
      showPdfData,
    },
    ref
  ) => {
    return (
      <div className={`pdf-container`}>
        <div>
          <div className="cii-chart-pdf" ref={componentRef1}>
            <CiiChart showBtn={false} />
          </div>

          <div className="ets-chart-pdf mt-5" ref={componentRef2}>
            <EtsTab showBtn={false} />
          </div>

          <div className="imo-chart-pdf mt-5" ref={componentRef3}>
            <IMOTab showBtn={false} />
          </div>

          <div className="eufm-chart-pdf mt-5" ref={componentRef4}>
            <EuFmTab showBtn={false} />
          </div>

          <div className="emission-chart-pdf mt-5" ref={componentRef5}>
            <EmissionTab showBtn={false} />
          </div>

          <div className="fuel-chart-pdf mt-5" ref={componentRef6}>
            <FuelTab showBtn={false} />
          </div>

          <div className="recommendation-table-pdf mt-5" ref={componentRef7}>
            <RecommendationTab showBtn={false} />
          </div>
        </div>
      </div>
    );
  }
);

export default Pdf;
