import CloseIcon from "../assets/closeIcon2.svg";

const InfoModal = ({ show, handleClose, data, ref }) => {
  return (
    show && (
      <div className="chart-information-contain" ref={ref}>
        <div
          className="d-flex justify-content-end cursor-pointer"
          onClick={handleClose}
        >
          <img src={CloseIcon} alt="close" />
        </div>
        {data && (
          <div
            className="text-info-modal"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        )}
      </div>
    )
  );
};

export default InfoModal;
