import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { barPatterns } from "../../utils/barPatterns";
import Loading from "../Loading";
import InfoModal from "../InfoModal";
import { useDispatch, useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ChartDataLabels
);

const CiiChart = ({ activeTab, showBtn = true }) => {
  const { fromYear } = useSelector((state) => state.selectedYearItem);
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [value, setValue] = useState(null);
  const [designation, setDesignation] = useState([]);
  const [attainedCii, setAttainedCii] = useState([]);
  const [targetCii, setTargetCii] = useState([]);
  const [chartKey, setChartKey] = useState(0);
  const [show, setShow] = useState(false);

  const CiiData = useSelector((state) => state.pdfData);
  const ciiChartRef = useRef(null);
  const ciiContainerRef = useRef(null);

  useEffect(() => {
    if (Object.keys(CiiData).length > 0) {
      const resultsCII = CiiData?.myCiiResults?.myResultsCII;
      const sortedResultCii = Object.fromEntries(
        Object.entries(resultsCII).sort(
          (a, b) => a[1].ciihorizontalScore - b[1].ciihorizontalScore
        )
      );
      const labels = Object.keys(sortedResultCii);
      const values = Object.values(sortedResultCii).map(
        (i) => i.ciihorizontalScore
      );
      const attainedCII = Object.values(sortedResultCii).map(
        (i) => i.attainedCII
      );
      const targetCII = Object.values(sortedResultCii).map((i) => i.targetCII.toFixed(2)+ " ("+i.targetCIIchar+")");
      const designations = Object.values(sortedResultCii).map(
        (i) => i.ciidesignation
      );
      const bg = Object.values(sortedResultCii).map((i) => i.colorHex);

      setValue(values);
      setDesignation(designations);
      setAttainedCii(attainedCII);
      setTargetCii(targetCII);

      const datasetCii = [
        {
          label: "CII data",
          backgroundColor: bg,
          borderColor: bg,
          borderWidth: 2,
          data: values,
          barThickness: 10,
          borderSkipped: false,
          borderRadius: 8,
        },
      ];

      setLabel(labels);
      setDataset(datasetCii);
    }
  }, [CiiData]);

  useEffect(() => {
    if (activeTab === 1) {
      setChartKey((prevKey) => prevKey + 1);
    }
  }, [activeTab]);

  const data = {
    labels: label,
    datasets: dataset,
  };

  Tooltip.positioners.myCustomPositioner = function(elements, eventPosition) {
    const tooltip = this;

    const x = eventPosition.x;
    const y = eventPosition.y;

    return {
        x: x,
        y: y,
        xAlign: 'center',
        yAlign: 'bottom'
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: null },
    indexAxis: "y",
    scales: {
      x: {
        position: "top",
        border: {
          display: false,
        },
        grid: {
          display: true,
          lineWidth: 1.5,
          z: 3,
        },
        ticks: {
          display: false,
          callback: (value, index) => ["A", "B", "C", "D", "E"][index],
          color: "#000000",
          labelOffset: 100,
          font: {
            size: 14,
            weight: "bold",
            family: "Cabin, sans-serif"
          },
          stepSize: 1,
        },
        min: 1,
        max: 6,
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          crossAlign: "far",
          color: "#000000",
          padding: 10,
          font: {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"
          },
        },
        border: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 75
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
          position: "myCustomPositionerLeft",
          displayColors: false,
          callbacks: {
              label: function (context) {
                  const dataIndex = context.dataIndex;
                  const ciiRating = designation[dataIndex] || 'N/A';
                  const attained = (attainedCii[dataIndex] !== undefined) ? parseFloat(attainedCii[dataIndex]).toFixed(2) : 'N/A';
                  const target = (targetCii[dataIndex] && targetCii[dataIndex].match(/[\d.]+/)) ? parseFloat(targetCii[dataIndex].match(/[\d.]+/)[0]).toFixed(2) : 'N/A';

                  return [
                      `CII Rating: ${ciiRating}`,
                      `Attained CII: ${attained}`,
                      `Target CII: ${target}`,
                  ];
              },
              afterLabel: function (context) {
                  const dataIndex = context.dataIndex;
                  const attained = (attainedCii[dataIndex] !== undefined) ? parseFloat(attainedCii[dataIndex]).toFixed(2) : null;
                  const target = (targetCii[dataIndex] && targetCii[dataIndex].match(/[\d.]+/)) ? parseFloat(targetCii[dataIndex].match(/[\d.]+/)[0]).toFixed(2) : null;

                  if (attained !== null && attained === target) {
                      return '𝐂𝐈𝐈 𝐀𝐓 𝐇𝐀𝐑𝐃 𝐋𝐈𝐌𝐈𝐓';
                  }
                  return '';
              },
              title: function (context) {
                  return context[0]?.label + ': ' || '';
              },
          },
      },
      datalabels: {
        align: "center",
        anchor: "end",
        color: function (context) {
          const attained = parseFloat(attainedCii[context.dataIndex]).toFixed(2);
          const target = parseFloat(targetCii[context.dataIndex].match(/[\d.]+/)[0]).toFixed(2);
          return attained === target ? "#000000" : "#FFFFFF";
        },
        backgroundColor: function (context) {
          const attained = parseFloat(attainedCii[context.dataIndex]).toFixed(2);
          const target = parseFloat(targetCii[context.dataIndex].match(/[\d.]+/)[0]).toFixed(2);
          return attained === target ? "#f0f3f9" : "#2c8ec6";
        },
        borderRadius: 30,
        borderWidth: function (context) {
          const attained = parseFloat(attainedCii[context.dataIndex]).toFixed(2);
          const target = parseFloat(targetCii[context.dataIndex].match(/[\d.]+/)[0]).toFixed(2);
          return attained === target ? 2 : 0;
        },
        borderColor: function (context) {
          const attained = parseFloat(attainedCii[context.dataIndex]).toFixed(2);
          const target = parseFloat(targetCii[context.dataIndex].match(/[\d.]+/)[0]).toFixed(2);
          return attained === target ? "#000000" : "#2c8ec6";
        },
        padding: function (context) {
          const attained = parseFloat(attainedCii[context.dataIndex]).toFixed(2);
          const target = parseFloat(targetCii[context.dataIndex].match(/[\d.]+/)[0]).toFixed(2);
          return attained === target ? {left: 4,
          right: 5,
          top: 4,
            bottom: 2,
          } : {
          left: 10,
          right: 10,
          top: 8,
          bottom: 6,
          }
          
        },
        font: function (context) {
          return {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"
          };
        },
        formatter: function (value, context) {
          if (context && !isNaN(value)) {
            const textInsideCircle = designation.length > 0 ? designation[context.dataIndex] : "A";
            const attained = parseFloat(attainedCii[context.dataIndex]).toFixed(2);
            const target = parseFloat(targetCii[context.dataIndex].match(/[\d.]+/)[0]).toFixed(2);
            const labelWithExclamation = attained === target ? `${textInsideCircle} !` : textInsideCircle;
            return labelWithExclamation;
          } else {
            return null;
          }
        },
      },
    },
  };

  useEffect(() => {
    if (show) {
      ciiContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    ciiChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div ref={ciiContainerRef}>
      <div className="tab-content-title">
      <h2>
        CII <span className="fs-4"> {fromYear.item}</span>
      </h2>
        {showBtn && (
          <button className="btn btn-primary" onClick={() => setShow(!show)}>
            Notes and Sources
          </button>
        )}
      </div>
      <div className="fleet-chart-container">
        {Object.keys(CiiData).length === 0 && (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Loading loading={Object.keys(CiiData).length === 0} />
          </div>
        )}
        {Object.keys(CiiData).length > 0 && (
          <Bar
            data={data}
            options={options}
            plugins={[barPatterns]}
            key={chartKey}
          />
        )}
      </div>
      <InfoModal
        ref={ciiChartRef}
        show={show}
        handleClose={handleClose}
        data={CiiData?.myCiiResults?.comments}
      />
    </div>
  );
};

export default CiiChart;